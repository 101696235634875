import React, { useState } from 'react';

import RoundedButton from '../../ui/RoundedButton';
import ShopperBackground from '../shopper/ShopperBackground';
import TravelerMountainBackground from '../traveler/TravelerMountainBackground';
import MediumPadding from '../../ui/MediumPadding';
import RegisterModal from '../../ui/RegisterModal';
import ModalSuscription from '../../ui/ModalSuscription';

const ComponentMountain = ({
  img,
  text,
  textButton,
  title,
  altShopper,
  altTraveler,
  traveler = false,
}) => {
  const [modal, setModal] = useState(false);
  const [user_type, setUser_Type] = useState('Birdybacker');

  const handleOnClick = (userType) => {
    setUser_Type(userType);
    setModal(true);
  };

  if (traveler) {
    return (
      <TravelerMountainBackground>
        <MediumPadding>
          {/*           <RegisterModal
            modal={modal}
            setModal={setModal}
            user_type={user_type}
          /> */}
          <ModalSuscription
            modalOpen={modal}
            setModalOpen={setModal}
            typeUser={user_type}
          />

          <div className="flex flex-col md:flex-row justify-center px-[2rem] gap-[2rem] lg:gap-[4rem] items-center w-full h-full">
            <div className="w-[90vw] lg:w-auto">
              <div className="flex flex-col items-center md:items-start text-center md:text-start wow fadeInUp">
                <div className="flex flex-col justify-center md:justify-start items-center md:items-start w-full">
                  {title}
                </div>

                <div className="flex justify-center md:justify-start w-full">
                  <div className="w-[80px] h-[3px] bg-[#C6D12D] mt-[1rem] md:mt-[2rem] mb-[1rem] md:mb-[2rem]" />
                </div>

                {text}

                <br className="hidden md:block" />

                {textButton && (
                  <RoundedButton
                    onClick={() => handleOnClick('Birdybacker')}
                    text={textButton}
                  />
                )}
              </div>
            </div>

            <div className="flex justify-center w-[90vw] lg:w-auto">
              <img
                alt={altTraveler}
                className="w-[85vw] max-w-[300px] md:max-w-[460px] lg:max-w-[550px] wow fadeInUp"
                src={img}
              />
            </div>
          </div>
        </MediumPadding>
      </TravelerMountainBackground>
    );
  }

  return (
    <ShopperBackground>
      <MediumPadding>
        {/*         <RegisterModal
          modal={modal}
          setModal={setModal}
          user_type={user_type}
        /> */}
        <ModalSuscription
          modalOpen={modal}
          setModalOpen={setModal}
          typeUser={user_type}
        />

        <div className="flex flex-col md:flex-row justify-center px-[2rem] gap-0 lg:gap-[4rem] items-center w-full h-full">
          <div className="w-[90vw] lg:w-auto">
            <div className="flex flex-col items-center md:items-start text-center md:text-start wow fadeInUp">
              <div className="flex flex-col justify-center md:justify-start items-center md:items-start w-full">
                {title}
              </div>

              <div className="flex justify-center md:justify-start w-full">
                <div className="w-[80px] h-[3px] bg-[#C6D12D] mt-[1rem] md:mt-[2rem] mb-[1rem] md:mb-[2rem]" />
              </div>

              {text}

              <br className="hidden md:block" />

              <RoundedButton
                onClick={() => handleOnClick('Birdybuyer')}
                text={textButton}
              />
            </div>
          </div>

          <div className="flex justify-center w-[90vw] lg:w-auto">
            <img
              alt={altShopper}
              className="w-[85vw] max-w-[300px] md:max-w-[460px] lg:max-w-[550px] wow fadeInUp"
              src={img}
            />
          </div>
        </div>
      </MediumPadding>
    </ShopperBackground>
  );
};

export default ComponentMountain;
