import axios from './axios';

export const airlinesApi = {
  path: '/airlines',
  async list_airlines() {
    return await axios.get(`${this.path}`, {
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
};
