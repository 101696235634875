import React from 'react';

const H1 = ({ className, children }) => {
  return (
    <h1
      className={`${
        className && className
      } text-[36px] md:text-[46px] lg:text-[56px]`}
    >
      {children}
    </h1>
  );
};

export default H1;
