import React, { useState } from 'react';
import { t } from 'i18next';
import GridLayout from "react-grid-layout";
import { useDispatch, useSelector } from 'react-redux'
import { Responsive, WidthProvider } from "react-grid-layout";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

// Styles
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

// components
import CardProduct from '../../components/buyer/components/Product/CardProduct';
import Discount from '../../components/pages/home/Discount';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const Home = () => {
  const auth = useSelector((state) => state.auth);

  const { user } = auth;

  const [open, setOpen] = useState(false);

  const layout = [
    { i: "a", x: 0, y: 0, w: 2, h: 5, static: true },
    { i: "b", x: 2, y: 0, w: 4, h: 4, static: true },
    { i: "c", x: 0, y: 5, w: 2, h: 4, static: true },
    { i: "d", x: 0, y: 9, w: 4, h: 4, static: true },
    { i: "e", x: 2, y: 4, w: 2, h: 5, static: true },
    { i: "f", x: 4, y: 4, w: 2, h: 9, static: true },
  ];

  return (
    <>
      <div className='mt-10 text-[#0A7379]'>
        <h5 className="inline-flex w-[362px] h-[50px] top-[97px] left-[2px] font-Montserrat font-bold">
          {t('Categoria de productos')}
        </h5>
      </div>
      {/*categoria de descuentos Carousel*/}
      <Swiper
        style={{
          '--swiper-navigation-color': '#005151',
        }}
        slidesPerView={3}
        spaceBetween={30}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="max-w-[1600px] z-0"
      >
        <SwiperSlide className="flex">
          <Discount bgColor="a8efd9" />
        </SwiperSlide>

        <SwiperSlide className="flex">
          <Discount bgColor="E9ED4A" />
        </SwiperSlide>

        <SwiperSlide className="flex">
          <Discount bgColor="FFBB69" />
        </SwiperSlide>

        <SwiperSlide className="flex">
          <Discount bgColor="E9ED4A" />
        </SwiperSlide>
      </Swiper>
      {/* Grid productos */}
      <div className="mt-2 bg-blue-950 ">
        <GridLayout
          className="layout mt-10"
          layout={layout}
          cols={12}
          rowHeight={30}
          width={1200}
        >
          <div key="a" className='bg-red-700'>a</div>
          <div key="b" className='bg-blue-700'>b</div>
          <div key="c" className='bg-green-700'>c</div>
          <div key="d" className='bg-yellow-700'>d</div>
          <div key="e" className='bg-pink-700'>e</div>
          <div key="f" className='bg-purple-700'>f</div>
        </GridLayout>
      </div>
      {/*  Mis productos favoritos */}
      <div div className='mt-10 text-[#0A7379]' >
        <h5 className="inline-flex w-[362px] h-[50px] top-[97px] left-[2px] font-Montserrat font-bold">
          {t('Mis favoritos')}
        </h5>
        <div className="flex justify-center">
          <CardProduct />
          <CardProduct />
          <CardProduct />
        </div>
      </div >
    </>
  );
};

export default Home;
