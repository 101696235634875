import React from 'react';

import Section from '../../ui/Section';

import largeRightOrangeCloud from '../../../assets/background/nube-grande-derecha-naranja-birdybuyer.svg';
import rightMountain from '../../../assets/background/montaña-derecha-azul-birdybacker.svg';

const ShopperBackground = ({ children }) => {
  return (
    <Section>
      <img
        className="absolute rotate-180 w-[240px] md:w-auto top-[-8vh] left-0 z-[-1]"
        src={largeRightOrangeCloud}
        alt="Nube derecha grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute h-full md:h-auto w-full top-[260px] md:top-auto bottom-0 right-0 z-[-1]"
        src={rightMountain}
        alt="Montaña derecha azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <div className="absolute bg-[#CFF6EB] h-[72px] md:h-[2vh] lg:h-[10vh] w-full bottom-[-2px] right-0 z-[-1]" />

      {children}
    </Section>
  );
};

export default ShopperBackground;
