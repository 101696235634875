import React from 'react';

import { t } from 'i18next';

import CommitmentsBackground from './CommitmentsBackground';
import LargePadding from '../../ui/LargePadding';

import trust from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/compromiso-birdyback-confianza.svg';
import lowPrices from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/compromiso-birdyback-precios-bajos-pagos-justos.svg';
import costumerSupport from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/compromiso-birdyback-servicio-al-cliente.svg';
import P from '../../typography/P';

const CommitmentsContainer = () => {
  return (
    <CommitmentsBackground>
      <LargePadding>
        <div className="flex flex-col lg:flex-row justify-center items-center gap-[10vw] h-full">
          <div className="flex flex-col items-center gap-[2rem]">
            <div className="flex flex-col items-center gap-[1.5rem] p-[1rem] m-auto">
              <div className="flex justify-center items-center">
                <img
                  className="w-[180px] md:w-[250px]"
                  src={trust}
                  alt="Compromiso de Birdyback: Confianza"
                />
              </div>
              <P className="text-center font-[400] leading-[30px] w-[12rem] md:w-[16rem]">
                {t(
                  'Ofrecemos claridad y transparencia en el proceso, ¡lo prometemos!',
                )}
              </P>
            </div>
          </div>

          <div className="flex flex-col items-center gap-[2rem]">
            <div className="flex flex-col items-center gap-[1.5rem] p-[1rem] m-auto">
              <div className="flex justify-center items-center">
                <img
                  className="w-[180px] md:w-[250px]"
                  src={lowPrices}
                  alt="Compromiso de Birdyback: Precios bajos y pagos justos"
                />
              </div>
              <P className="text-center font-[400] leading-[30px] w-[12rem] md:w-[16rem]">
                {t('Tenemos precios justos y regularizados para que tú ganes.')}
              </P>
            </div>
          </div>

          <div className="flex flex-col items-center gap-[2rem]">
            <div className="flex flex-col items-center gap-[1.5rem] p-[1rem] m-auto">
              <div className="flex justify-center items-center">
                <img
                  className="w-[180px] md:w-[250px]"
                  src={costumerSupport}
                  alt="Compromiso de Birdyback: Servicio al cliente"
                />
              </div>
              <P className="text-center font-[400] leading-[30px] w-[12rem] md:w-[16rem]">
                {t(
                  'Queremos que sientas tranquilidad. Por eso, estamos para escucharte.',
                )}
              </P>
            </div>
          </div>
        </div>
      </LargePadding>
    </CommitmentsBackground>
  );
};

export default CommitmentsContainer;
