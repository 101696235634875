import React from 'react';

import './TextSlide.css';

const TextSlide = () => {
  return (
    <section className="slider-security my-[6rem]">
      <div className="slide-track-security text-center">
        <div className="slide-security">
          <h2 className="text-[#E57D00]">-</h2>
          <h2 className="text-[#E57D00]">Ganar viajando</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#29B78B]">-</h2>
          <h2 className="text-[#29B78B]">Comprar fácil</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#2A9393]">-</h2>
          <h2 className="text-[#2A9393]">Dinero seguro</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#E57D00]">-</h2>
          <h2 className="text-[#E57D00]">Proceso confiable</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#29B78B]">-</h2>
          <h2 className="text-[#29B78B]">Envío internacional</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#2A9393]">-</h2>
          <h2 className="text-[#2A9393]">Comunidad accesible</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#E57D00]">-</h2>
          <h2 className="text-[#E57D00]">Marcas aliadas</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#E57D00]">-</h2>
          <h2 className="text-[#E57D00]">Ganar viajando</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#29B78B]">-</h2>
          <h2 className="text-[#29B78B]">Comprar fácil</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#2A9393]">-</h2>
          <h2 className="text-[#2A9393]">Dinero seguro</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#E57D00]">-</h2>
          <h2 className="text-[#E57D00]">Proceso confiable</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#29B78B]">-</h2>
          <h2 className="text-[#29B78B]">Envío internacional</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#2A9393]">-</h2>
          <h2 className="text-[#2A9393]">Comunidad accesible</h2>
        </div>
        <div className="slide-security">
          <h2 className="text-[#E57D00]">-</h2>
          <h2 className="text-[#E57D00]">Marcas aliadas</h2>
        </div>
      </div>
    </section>
  );
};

export default TextSlide;
