import React from 'react';

import Section from '../../ui/Section';

import smallCenteredOrangeCloud from '../../../assets/background/nube-pequeña-centrada-naranja-birdybuyer.svg';

const TravelerBackground = ({ children }) => {
  return (
    <Section>
      <img
        className="absolute top-[5vh] md:top-[10vh] left-[10vw] z-[0]"
        src={smallCenteredOrangeCloud}
        alt="Nube centrada pequeña naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      {children}
    </Section>
  );
};

export default TravelerBackground;
