import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth as authorization } from '../../api/auth';

let initialState = {
  success: false,
  user: {
    id: null,
    sub: null,
    owned_by: null,
    status: null,
    created_at: null,
    updated_at: null,
    user_type: null,
    photo: null,
    name: null,
    nickname: null,
    last_name: null,
    email: null,
    phone: null,
  },
  AccessToken: null,
  ExpiresIn: 0,
  IdToken: null,
  RefreshToken: null,
  TokenType: null,
  loading: false,
};

if (localStorage.getItem('auth')) {
  const auth = JSON.parse(localStorage.getItem('auth'));
  initialState = auth;
}

export const login = createAsyncThunk(
  'auth/login',
  async (data, { rejectWithValue }) => {
    try {
      const response = await authorization.singIn(data);

      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const getUserAuth = createAsyncThunk(
  'user/getUserAuth',
  async (data) => {
    const response = await authorization.getUser(data);
    console.log(response);
    return response.data.result;
  },
);

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      return {
        ...state,
        ...action.payload,
        success: true,
      };
    },
    setUser: (state, action) => {
      return {
        ...state,
        user: {
          ...action.payload,
        },
        success: true,
      };
    },
    changeNickname: (state, action) => {
      return {
        ...state,
        user: {
          ...state.user,
          nickname: action.payload.nickname,
        },
      };
    },
    changePhone: (state, action) => {
      state.phone = action.payload.phone;
    },
    changePhoto: (state, action) => {
      state.photo = action.payload.photo;
    },
    changeEmail: (state, action) => {
      state.email = action.payload.email;
    },
    changeSuccess: (state, action) => {
      state.success = action.payload.success;
    },
    changeUserType: (state, action) => {
      state.userType = action.payload.userType;
    },
    logoutUser: (state, action) => {
      localStorage.removeItem('auth');
      return {
        ...state,
        success: false,
        userType: 'owner',
        AccessToken: null,
        ExpiresIn: 0,
        IdToken: null,
        RefreshToken: null,
        TokenType: null,
        loading: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: null,
        };
      })
      .addCase(login.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload.result.AuthenticationResult,
          loading: false,
          success: true,
        };
      })
      .addCase(login.rejected, (state, action) => {
        return { ...state, loading: false, error: action.error.message };
      });
    builder
      .addCase(getUserAuth.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: null,
        };
      })
      .addCase(getUserAuth.fulfilled, (state, action) => {
        return {
          ...state,
          user: {
            ...action.payload,
          },
          loading: false,
          success: true,
        };
      })
      .addCase(getUserAuth.rejected, (state, action) => {
        return { ...state, loading: false, error: action.error.message };
      });
  },
});

export const {
  setAuth,
  setUser,
  changeSuccess,
  changeUserType,
  logoutUser,
  changeNickname,
  changePhone,
  changePhoto,
  changeEmail,
  changeUser,
} = auth.actions;
export default auth.reducer;
