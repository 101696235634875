import React from 'react';

import Section from '../../ui/Section';

import largeRightCloud from '../../../assets/background/nube-grande-derecha-azul-birdybacker.svg';
import smallCenteredOrangeCloud from '../../../assets/background/nube-pequeña-centrada-naranja-birdybuyer.svg';
import smallLeftCloud from '../../../assets/background/nube-pequeña-izquierda-azul-birdybacker.svg';
import largeCenteredCloud from '../../../assets/background/nube-grande-centrada-azul-birdybacker.svg';
import largeCenteredOrangeCloud from '../../../assets/background/nube-grande-centrada-azul-birdybacker.svg';

const QABackground = ({ children }) => {
  return (
    <Section>
      <img
        className="absolute w-[240px] md:w-auto top-[5vh] right-0 z-[-1]"
        src={largeRightCloud}
        alt="Nube derecha grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[160px] md:w-auto top-[30vh] left-0 z-[-1]"
        src={smallLeftCloud}
        alt="Nube izquierda pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[240px] md:w-auto top-[80vh] right-[40vw] z-[-1]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[240px] md:w-auto top-[120vh] right-0 z-[-1]"
        src={largeRightCloud}
        alt="Nube derecha grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {children}
    </Section>
  );
};

export default QABackground;
