import React from 'react';

import { t } from 'i18next';

import hello from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/mapa-usa-colombia-birdybacker-envio-paquete.svg';
import HomeBackground from '../common/HomeBackground';
import MediumPadding from '../../ui/MediumPadding';
import P from '../../typography/P';

const FirstSection = () => {
  return (
    <HomeBackground>
      <MediumPadding>
        <div className="flex flex-col justify-center w-full h-full md:flex-row gap-[1rem] lg:gap-[3rem] z-[0]">
          <div className="flex flex-row md:flex-col justify-center items-center">
            <img
              src={hello}
              className="w-[300px] md:w-[550px] wow fadeInUp"
              alt="Mapa de envio de un Birdybacker (viajero) para transportar y enviar un paquete a un Birdybuyer (comprador) desde Estados Unidos a Colombia"
            />
          </div>

          <div className="flex flex-col justify-center items-center md:items-start text-center md:text-left wow fadeInLeft">
            <div className="flex flex-col justify-center gap-0">
              <h3 className="font-[600] text-primary">{t('¡Hola!')}</h3>

              <h3 className="font-[600] text-primary">
                {t('Amamos que estés acá')}
              </h3>
            </div>

            <div className="flex justify-center md:justify-start">
              <div className="w-[80px] h-[3px] bg-[#C6D12D] mt-[1rem] md:mt-[2rem] mb-[1rem] md:mb-[2rem]" />
            </div>

            <P className="mb-3 md:mb-0 w-[300px] lg:w-[28vw]">
              {t(
                'Somos el puente entre tu tienda internacional favorita y tú. Gracias a nuestra comunidad',
              )}{' '}
              <span className="font-[700]">
                {t('podemos hacer llegar eso que necesitas')}
              </span>
              {', '}
              <span className="font-[700]">
                {t('quieres o se te antoja hasta la puerta de tu casa')}
              </span>
              {', '}
              {t('mientras te tomas el cafecito.')}
            </P>

            <br className="hidden md:block" />

            <P className="hidden md:block mb-3 md:mb-0 w-[300px] lg:w-[28vw]">
              {t('Y bueno,')}{' '}
              <span className="font-[700]">
                {t('si lo que quieres es ganar dinero extra mientras viajas')}
              </span>
              {', '}
              <span className="font-[700]">
                {t('también estamos para ayudarte con esto')}
              </span>
              {'.'}
            </P>

            <br className="hidden md:block" />

            <P className="hidden md:block mb-3 md:mb-0 w-[300px] lg:w-[28vw]">
              {t(
                'Pasa adelante, nosotros nos encargamos. Esta es nuestra Birdycommunity, y ahora es tuya también.',
              )}
            </P>
          </div>
        </div>
      </MediumPadding>
    </HomeBackground>
  );
};

export default FirstSection;
