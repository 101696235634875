import React from 'react';

import Section from '../../ui/Section';

import largeRightCloud from '../../../assets/background/nube-grande-derecha-azul-birdybacker.svg';
import smallLeftCloud from '../../../assets/background/nube-pequeña-izquierda-azul-birdybacker.svg';
import BackgroundPassportBirdyback from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/background-pasaporte-identidad-birdyback.svg';

const OurCommitmentBackground = ({ children }) => {
  return (
    <Section h="h-full">
      <img
        className="absolute w-[240px] md:w-auto top-[5vh] right-0 z-[-1]"
        src={largeRightCloud}
        alt="Nube derecha grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[160px] md:w-auto bottom-[18vh] lg:bottom-[30vh] left-0 z-[-1]"
        src={smallLeftCloud}
        alt="Nube izquierda pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[160px] md:w-[540px] bottom-[18vh] lg:bottom-[-2rem] left-[30vw] z-[-1]"
        src={BackgroundPassportBirdyback}
        alt="Background del pasaporte de identidad para emprender el vuelo de Birdyback"
      />

      {children}
    </Section>
  );
};

export default OurCommitmentBackground;
