import React from 'react';

import Section from '../../ui/Section';

import smallRightCloud from '../../../assets/background/nube-pequeña-derecha-azul-birdybacker.svg';
import largeCenteredCloud from '../../../assets/background/nube-grande-centrada-azul-birdybacker.svg';
import rightOrangeMountain from '../../../assets/background/montaña-derecha-naranja-birdybuyer.png';

const SecondBackground = ({ children }) => {
  return (
    <Section>
      <img
        className="absolute w-[240px] md:w-[320px] lg:w-auto top-0 md:top-[5vh] 2xl:top-[-5vh] right-0 z-[-1]"
        src={smallRightCloud}
        alt="Nube derecha pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="block md:hidden absolute w-[320px] lg:w-auto top-[70vh] md:top-[50vh] right-[10vw] z-[-1]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[260px] lg:w-auto top-[100vh] md:top-[50vh] xl:top-[30vh] left-[10vw] z-[-2]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute h-[100vh] md:h-[50vh] w-full md:top-1/2 bottom-0 right-0 z-[-1]"
        src={rightOrangeMountain}
        alt="Montaña derecha naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      {children}
    </Section>
  );
};

export default SecondBackground;
