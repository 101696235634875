import React from 'react';

import { t } from 'i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import one from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/valor-birdyback-harder-better-faster-stronger.svg';
import two from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/valor-birdyback-unite-serving-unique-love.svg';
import three from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/valor-birdyback-keep-on-keeping-on.svg';
import four from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/valor-birdyback-dont-know-lets-learn.svg';
import ValuesCard from './ValuesCard';
import HomeBackground from '../common/HomeBackground';
import MediumPadding from '../../ui/MediumPadding';

const ValuesContainer = () => {
  return (
    <HomeBackground>
      <MediumPadding>
        <section className="flex flex-col justify-center gap-[2rem] h-full w-full">
          <div className="text-center">
            <h2 className="text-primary">{t('Nuestros valores')}</h2>
          </div>

          <div className="px-[1rem] md:px-[8rem]">
            <Swiper
              style={{
                '--swiper-navigation-color': '#005151',
              }}
              slidesPerView={3}
              spaceBetween={30}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="max-w-[1600px] z-0"
            >
              <SwiperSlide className="flex">
                <ValuesCard
                  imagen={one}
                  text="Promovemos la eficiencia, disciplina y, por supuesto, la excelencia."
                  alt="Valor de Birdyback: Harder, Better, Faster, Stronger."
                />
              </SwiperSlide>

              <SwiperSlide className="flex">
                <ValuesCard
                  imagen={two}
                  text="Amamos conectar y servir a las personas."
                  alt="Valor de Birdyback: United serving with unique love."
                />
              </SwiperSlide>

              <SwiperSlide className="flex">
                <ValuesCard
                  imagen={three}
                  text="Somos resilientes, persistentes y buscamos constantemente la superación personal."
                  alt="Valor de Birdyback: Keep on, keeping on."
                />
              </SwiperSlide>

              <SwiperSlide className="flex">
                <ValuesCard
                  imagen={four}
                  text="Innovamos mientras creamos; disfrutamos ser curiosos y aventureros."
                  alt="Valor de Birdyback: We don't say dunno, we say let's learn"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
      </MediumPadding>
    </HomeBackground>
  );
};

export default ValuesContainer;
