import React from 'react';

import { t } from 'i18next';

import ContainerSecurityBackground from './ContainerSecurityBackground';

const Container = () => {
  return (
    <ContainerSecurityBackground>
      <div className="flex justify-center items-center">
        <div className="flex flex-col gap-[1rem] md:gap-0 justify-center py-[3.5rem] md:py-[7rem] p-[2rem] md:p-[7rem] text-center max-w-[1200px]">
          <p className="hidden md:block text-[16px] md:text-[24px] font-[400] text-black wow fadeInUp">
            <span className="font-[700]">
              {t(
                'Desde que empezamos a soñar con hacer realidad Birdyback, tu seguridad y la de tu producto han sido nuestra prioridad',
              )}
            </span>
            {'. '}
            {t(
              'Queremos tu tranquilidad; que como Birdybacker, puedas moverte con comodidad, y que como Birdybuyer, recibas tu compra sin inconvenientes. Por eso, acá te mostramos nuestro',
            )}{' '}
            <span className="font-[700]">
              {t('sistema de prevención de incidentes,')}
            </span>{' '}
            {t(
              'para que sientas confianza haciendo parte de nuestra Birdycommunity.',
            )}
          </p>

          <p className="block md:hidden text-[16px] md:text-[24px] font-[700] text-black wow fadeInUp">
            {t(
              'Desde que empezamos a soñar con hacer realidad Birdyback, tu seguridad y la de tu producto han sido nuestra prioridad.',
            )}
          </p>

          <p className="block md:hidden text-[16px] md:text-[24px] font-[400] text-black wow fadeInUp">
            {t(
              'Queremos tu tranquilidad; que como Birdybacker, puedas moverte con comodidad, y que como Birdybuyer, recibas tu compra sin inconvenientes.',
            )}
          </p>
        </div>
      </div>
    </ContainerSecurityBackground>
  );
};

export default Container;
