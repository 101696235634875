import React from 'react';

import { t } from 'i18next';

import HandleScroll from '../../util/functions/handleScroll';
import RoundedButton from './RoundedButton';
import SmallPadding from './SmallPadding';

const TextBanner = ({
  bold = false,
  redirectButton,
  title,
  text,
  textButton,
  onClick,
}) => {
  return (
    <SmallPadding>
      <section className="flex justify-center">
        <div className="flex flex-col justify-center items-center text-center gap-[1rem] w-[70vw] max-w-[1600px] h-full">
          <h2 className={bold ? 'text-primary font-[700]' : 'text-primary'}>
            {t(title)}
          </h2>

          {text}

          <RoundedButton
            onClick={onClick ? () => onClick() : () => HandleScroll()}
            redirect={redirectButton}
            text={textButton}
          />
        </div>
      </section>
    </SmallPadding>
  );
};

export default TextBanner;
