import React, { useState, useEffect } from 'react';

import { t } from 'i18next';
import { ConfigProvider, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import ResponsiveInputEmail from './ResponsiveInputEmail';

import '../../../src/assets/css/swalCustom.css';
import Swal from 'sweetalert2';

import 'animate.css'; // Import Animate.css

import smallCenteredOrangeCloud from '../../assets/background/nube-pequeña-centrada-naranja-birdybuyer.svg';
import largeLeftOrangeCloud from '../../assets/background/nube-grande-izquierda-naranja-birdybuyer.svg';
import largeRightCloud from '../../assets/background/nube-pequeña-derecha-azul-birdybacker.svg';
import HeaderBuyerOrTraveler from '../../components/ui/HeaderBuyerOrTraveler';
import checkItem from '../../assets/svg/icono-check-revisado-birdyback.svg';

//Api
import { PreRegister } from '../../api/preRegister'

const ModalSuscription = ({ modalOpen, setModalOpen, typeUser = 'Birdybacker' }) => {

  const nameElementsHeader = {
    firstNameElement: 'Birdybacker',
    SecondNameElement: 'Birdybuyer',
  }

  const [selectedOption, setSelectedOption] = useState(nameElementsHeader.firstNameElement);

  useEffect(() => {
    setSelectedOption(typeUser);
  }, [typeUser]);

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleOptionClick = (option) => {
    console.log("option:", option);
    setSelectedOption(option);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(''); // Reset error state when email changes
  };


const sendEmailData = async () => {
  try {
    console.log("Email:", email);
    console.log("type:", selectedOption);
    const res = await PreRegister.create_pre_register({ email, user_type: selectedOption });
    console.log(res);

    // You can close the modal here if there is no error
    setModalOpen(false);

    // const inputOptions = new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve({
    //       "Pobre": "1",
    //       "Mala": "2",
    //       "Regular": "3",
    //       "Buena": "4",
    //       "Excelente": "5",
    //     });
    //   }, 1000);
    // });
    // const { value: rating } = await Swal.fire({
    //   title: "Rating",
    //   text: "Selecciona como calificarías tu experiencia.",
    //   input: "radio",
    //   inputOptions,
    //   inputValidator: (value) => {
    //     if (!value) {
    //       return "Por favor no te ovides en seleccionar";
    //     }
    //   }
    // });
    // if (rating) {
    //   Swal.fire({ html: `Has seleccionado que la experiencia te ha parecido ${rating}` });
    // }

    // Trigger SweetAlert2 here
    if (res.status === 200) {
      if (selectedOption === nameElementsHeader.firstNameElement) {
        // Birdybacker success message
        Swal.fire({
          title: `<p class="swal-title">Mensaje Enviado</p>`,
          html: `<p class="swal-text">¡Gracias por tu pre-registro Birdybacker! Pronto te notificarémos para que empieces a alquilar un espacio de tu maleta y ganar dinero por hacerlo.</p>`,
          confirmButtonText: 'Entendido',
          customClass: {
            confirmButton: 'swal-custom-button',
            popup: 'swal-frame'
          },
          showClass: {
            popup: 'animate__animated animate__fadeInUp animate__faster',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutDown animate__faster',
          },
        });
      } else if (selectedOption === nameElementsHeader.SecondNameElement) {
        // Birdybuyer success message
        Swal.fire({
          title: 'Mensaje Enviado',
          text: '¡Gracias por tu pre-registro Birdybuyer! Pronto te notificarémos para que puedas empezar a comprar todos los productos que deseas en Estados Unidos.',
          confirmButtonText: 'Entendido',
          customClass: {
            confirmButton: 'swal-custom-button',
          },
          showClass: {
            popup: 'animate__animated animate__fadeInUp animate__faster',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutDown animate__faster',
          },
        });
      }
    } else {
      // Handle other status codes (e.g., show an error message)
      Swal.fire({
        title: 'Error de conexión',
        text: 'Se ha producido un error. Intenta más tarde',
        confirmButtonText: 'Entendido',
        customClass: {
          confirmButton: 'custom-swal-button',
        },
        showClass: {
          popup: 'animate__animated animate__fadeInUp animate__faster',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutDown animate__faster',
        },
      });
    }
  } catch (error) {
    console.log(error);
    // Lógica de manejo de errores y alertas
  }
};


  // const sendEmailData = async () => {
  //   try {
  //     console.log("Email:", email);
  //     console.log("type:", selectedOption);
  //     const res = await PreRegister.create_pre_register({ email, user_type: selectedOption });
  //     console.log(res);
  //     // You can close the modal here if there is no error
  //     setModalOpen(false);

  //     // Trigger SweetAlert2 here
  //     if (res.status === 200) {
  //       // Status is 200, show a success message
  //       Swal.fire({
  //         title: 'Mensaje Enviado',
  //         text: 'Nos pondremos en contacto contigo en los próximos días',
  //         confirmButtonText: 'Entendido',
  //         customClass: {
  //           confirmButton: 'custom-swal-button', // Apply your custom button class if needed
  //         },
  //         showClass: {
  //           popup: 'animate__animated animate__fadeInUp animate__faster',
  //         },
  //         hideClass: {
  //           popup: 'animate__animated animate__fadeOutDown animate__faster',
  //         },
  //       });
  //       // You can close the modal here if there is no error
  //       setOpen(false);
  //     } else {
  //       // Handle other status codes (e.g., show an error message)
  //       Swal.fire({
  //         title: 'Error de conexión',
  //         text: 'Se ha producido un error. Intenta más tarde',
  //         confirmButtonText: 'Entendido',
  //         customClass: {
  //           confirmButton: 'custom-swal-button', // Apply your custom button class if needed
  //         },
  //         showClass: {
  //           popup: 'animate__animated animate__fadeInUp animate__faster',
  //         },
  //         hideClass: {
  //           popup: 'animate__animated animate__fadeOutDown animate__faster',
  //         },
  //       });
  //     }

  //     // alertas que se envio correctamente
  //   } catch (error) {
  //     console.log(error);
  //     // Lógica de manejo de errores y alertas
  //   }
  // };


  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            borderRadiusLG: 40,
            paddingContentHorizontal: 0,
            paddingContentVertical: 0,
            paddingLG: 0,
            marginLG: 0,
            paddingMD: 0,
            paddingXL: 0,
            paddingSM: 0,
            paddingContentHorizontalLG: 0,
          },
        },
      }}
    >
      <Modal
        centered
        closeIcon={false}
        open={modalOpen}
        onOk={null}
        onCancel={null}
        footer={null}
        width={700}
      >
        <BackgroundModal>
          <div className="relative flex flex-col items-center gap-[1rem] py-[1rem] overflow-hidden z-[1]">
            <FontAwesomeIcon
              className="absolute top-[12px] right-[18px] text-[24px] cursor-pointer"
              onClick={() => setModalOpen(false)}
              icon={faXmark}
            />

            <div className="text-center">
              <h4 className="font-Montserrat text-primary font-[700]">
                {t('¡Sé Parte de la Birdycommunity!')}
              </h4>

              <h5 className="font-Montserrat text-primary">
                {t('Pre-regístrate ahora')}
              </h5>
            </div>

            <div className="flex  py-[1rem]">
              <div className="bg-[#C6D12D] h-[4px] w-[160px] rounded-lg" />
            </div>

            <div className="pl-[3rem]">
              <p>
                {t(
                  'Asegurate un lugar exclusivo antes de que abramos el registro oficial para tener oportunidades globales como Birdybuyer y Birdybacker.',
                )}
              </p>

              <p className="w-[362px] h-[50px] top-[97px] left-[2px] font-Montserrat font-bold text-[#0A7379] text-center flex items-center">
                {t('Elige lo que estas interesado ser:')}
              </p>
            </div>

            <HeaderBuyerOrTraveler
              selectedOption={selectedOption}
              handleOptionClick={handleOptionClick}
              firstNameElement={nameElementsHeader.firstNameElement}
              SecondNameElement={nameElementsHeader.SecondNameElement}
              content={
                <div className="flex gap-[1rem] px-[2rem]">
                  <img className="w-[30px]" src={checkItem} />
                  <p>
                    {selectedOption === nameElementsHeader.firstNameElement
                      ? t('Como Birdybacker ganar dinero alquilando un espacio en tu maleta, y hacer un dinerito extra por el cambio de dolares.')
                      : t('Como Birdybuyers acceder a nuevos mercados para hacer compras inteligentes, más económicas y de excelente calidad.')
                    }
                  </p>
                </div>
              }
            />

            <ResponsiveInputEmail
              value={email}
              onChange={handleEmailChange}
              handleButtonClick={sendEmailData}
            />
          </div>
        </BackgroundModal>
      </Modal>
    </ConfigProvider >
  );
};

const BackgroundModal = ({ children }) => {
  return (
    <section className="relative">
      <img
        className="absolute top-[5vh] left-[32px] z-[0]"
        src={smallCenteredOrangeCloud}
        alt="Nube centrada pequeña naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute bottom-[0] left-0 z-[0]"
        src={largeLeftOrangeCloud}
        alt="Nube izquierda grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute bottom-[160px] right-0 z-[0]"
        src={largeRightCloud}
      />

      {children}
    </section>
  );
};

export default ModalSuscription;
