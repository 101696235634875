import React from 'react';

import { t } from 'i18next';

import four from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdybacker-birdybuyer-birdyback-nave.svg';
import footerOrangeMountain from '../../../assets/background/footer-orange-mountain.svg';

const FirstBanner = () => {
  return (
    <section className="relative flex flex-col-reverse lg:flex-row justify-between items-center px-[1rem] lg:px-[8rem]">
      <div className="absolute top-0 right-0 w-full bg-[#FFE7CC] h-[200px] z-[-1]" />

      <img
        className="absolute top-[199px] right-0 w-full z-[-1]"
        src={footerOrangeMountain}
        alt='Nave volando llevando a la Birdycommunity: Birdybacker (viajero) y Birdybuyer (comprador) utilizando Birdyback'
      />

      <div className="flex flex-col gap-[1rem] text-start">
        <h2 className="font-[700] text-primary leading-[2.5rem] md:leading-[3.5rem]">
          {t('Puedes confiar en nosotros')}
        </h2>

        <h5 className="text-primary leading-[3rem]">
          {t('Somos de palabra, nos comprometemos:')}
        </h5>
      </div>

      <img className="w-[330px] md:w-[520px]" src={four} alt="" />
    </section>
  );
};

export default FirstBanner;
