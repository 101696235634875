import { configureStore } from '@reduxjs/toolkit';
import auth from '../features/auth/authSlide';
import userAddresses from '../features/user/userAddressesSlide';
import servicesSlide from '../features/user/servicesSlide';
import productsSlide from '../features/user/productsSlide';
import ordersSlide from '../features/user/ordersSlide';

export const store = configureStore({
  reducer: {
    auth,
    products: productsSlide,
    orders: ordersSlide,
    addresses: userAddresses,
    services: servicesSlide,
  },
});
