import axios from './axios';

export const auth = {
  path: '/auth',
  async signup(data) {
    const { email, password, user_type } = data;
    return await axios.post(`${this.path}/sign-up`, {
      email,
      password,
      user_type,
    });
  },
  async signupgoogle(data) {
    console.log(data);
    const { clientId, credential, select_by } = data;
    return await axios.post(`${this.path}/sign-up`, {
      token: credential,
    });
  },
  async confirm_signup(data) {
    const { email, code } = data;
    return await axios.post(`${this.path}/confirm-sign-up`, {
      email,
      code,
    });
  },
  async resend_confirmation_code(data) {
    const { email } = data;
    return await axios.post(`${this.path}/resend-confirmation-code`, {
      email,
    });
  },
  async signin(data) {
    const { email, password, userType } = data;
    return await axios.post(`${this.path}/login`, {
      email,
      password,
      user_type: userType,
    });
  },
  async forgot_password(data) {
    const { email } = data;
    return await axios.post(`${this.path}/forgot-password`, {
      email,
    });
  },
  async confirm_forgot_password(data) {
    const { email, code, password } = data;
    return await axios.post(`${this.path}/confirm-forgot-password`, {
      email,
      code,
      password,
    });
  },
  async get_user(authorization) {
    return await axios.get(`${this.path}/get-user`, {
      headers: { Authorization: authorization },
    });
  },
};
