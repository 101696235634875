import React from 'react';

const P = ({ className, children, style }) => {
  return (
    <p
      className={`text-[14px] md:text-[17px] lg:text-[18px] text-primary ${className && className
        }`}
      style={style}
    >
      {children}
    </p>
  );
};

export default P;
