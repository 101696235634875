const date = new Date();
let day = date.getDate() + 2;
let month = date.getMonth() + 1;
const year = date.getFullYear();
const allDays = new Date(year, month, 0).getDate();

const gettoday = () => {
  if (day > allDays) {
    day = day - allDays;
    month = month + 1;
    if (month > 9) {
      if (day < 10) {
        return year + '-' + month + '-0' + day;
      } else {
        return year + '-' + month + '-' + day;
      }
    } else {
      if (day < 10) {
        return year + '-0' + month + '-0' + day;
      } else {
        return year + '-0' + month + '-' + day;
      }
    }
  } else {
    if (month > 9) {
      if (day < 10) {
        return year + '-' + month + '-0' + day;
      } else {
        return year + '-' + month + '-' + day;
      }
    } else {
      if (day < 10) {
        return year + '-0' + month + '-0' + day;
      } else {
        return year + '-0' + month + '-' + day;
      }
    }
  }
};

export const today = gettoday();