import axios from './axios';

export const travelsApi = {
  path: '/travels',
  async create_travel(data) {
    return await axios.post(`${this.path}`, data, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem('birdyuser')),
      },
    });
  },
  async update_travel() {
    return await axios.put(`${this.path}/ `, {
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
  async list_travels() {
    return await axios.get(`${this.path}`, {
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
  async get_one(data) {
    const { id } = data;
    return await axios.get(`${this.path}/${id}`, {
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
  async delete_travel(data) {
    const { id } = data;
    return await axios.delete(`${this.path}/${id}`, {
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
};
