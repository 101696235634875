import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { travelsApi } from '../../api/travels';

const initialState = [];

export const getTravels = createAsyncThunk(
  'services/getTravels',
  async (Authorization, { rejectWithValue }) => {
    try {
      const response = await travelsApi.list_travels({ Authorization });

      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const services = createSlice({
  name: 'services',
  initialState,
  reducers: {
    addService: (state, action) => {
      const service = state.find((service) => service.id === action.payload.id);
      if (service) {
        service.process.step = action.payload.step;
        if (action.payload.verification) {
          service.process.verification = action.payload.verification;
        }
        if (action.payload.condition) {
          service.process.condition = action.payload.condition;
        }
        if (action.payload.arrival) {
          service.process.arrival = action.payload.arrival;
        }
        if (action.payload.shipping) {
          service.process.shipping = action.payload.shipping;
        }
      }
    },
    changeStep: (state, action) => {
      const service = state.find((service) => service.id === action.payload.id);
      if (service) {
        service.process.step = action.payload.step;
        if (action.payload.verification) {
          service.process.verification = action.payload.verification;
        }
        if (action.payload.condition) {
          service.process.condition = action.payload.condition;
        }
        if (action.payload.arrival) {
          service.process.arrival = action.payload.arrival;
        }
        if (action.payload.shipping) {
          service.process.shipping = action.payload.shipping;
        }
      }
    },
    changeSubstep: (state, action) => {
      const service = state.find((service) => service.id === action.payload.id);
      if (service) {
        service.process.subStep = action.payload.subStep;
        if (action.payload.start) {
          service.process.start = action.payload.start;
        }
        if (action.payload.onBoard) {
          service.process.onBoard = action.payload.onBoard;
        }
      }
    },
    changeStatus: (state, action) => {
      const service = state.find((service) => service.id === action.payload.id);
      if (service) {
        service.status = action.payload.status;
      }
    },
    deleteService: (state, action) => {
      const service = state.find((service) => service.id === action.payload);
      if (service) {
        state.splice(state.indexOf(service), 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTravels.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: null,
        };
      })
      .addCase(getTravels.fulfilled, (state, action) => {
        return {
          ...state,
          ...action.payload.result,
          loading: false,
          success: true,
        };
      })
      .addCase(getTravels.rejected, (state, action) => {
        return { ...state, loading: false, error: action.error.message };
      });
  },
});

export const { changeStep, changeSubstep, changeStatus, deleteService } =
  services.actions;
export default services.reducer;
