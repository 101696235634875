import React from 'react';

import { t } from 'i18next';

import OurCommitmentBackground from './OurCommitmentBackground';

import Commitment from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/pasaporte-identidad-birdyback.svg';

const OurCommitment = () => {
  return (
    <OurCommitmentBackground>
      <div className="flex flex-col justify-center items-center h-full">
        <div className="text-center mb-[2rem]">
          <h2 className="text-primary">{t('Te cuidamos')}</h2>
        </div>

        <div className="flex flex-col items-center text-center gap-[1.5rem]">
          <img className="w-[300px] lg:w-[440px]" src={Commitment} alt="Pasaporte de identidad para emprender el vuelo de Birdyback"/>

          <h5 className="font-Montserrat text-[20px] md:text-[32px] lg:text-[24px] text-primary w-[80vw] max-w-[900px]">
            {t('Queremos')}{' '}
            <span className="font-[700]">{t('conectarte')}</span>{' '}
            {t('con un Birdybacker o un Birdybuyer de una manera fácil.')}{' '}
            <span className="font-[700]">{t('Estamos para ayudarte')}</span>{' '}
            {t('con lo que necesitas, y para')}{' '}
            <span className="font-[700]">{t('solucionar los problemas')}</span>{' '}
            {t('que puedan surgir en el proceso.')}
          </h5>
        </div>
      </div>
    </OurCommitmentBackground>
  );
};

export default OurCommitment;
