import React from 'react';

import { t } from 'i18next';
import { Timeline, ConfigProvider } from 'antd';

import MediumPadding from '../../ui/MediumPadding';

import footerMountain from '../../../assets/background/birdyback-montaña-footer.svg';
import P from '../../typography/P';

const SteptoStep = ({ one, two, three, four, five, img, title }) => {
  return (
    <>
      <div className="relative bg-gradient-to-b from-[#CFF6EB] to-[#0B747A] flex flex-col gap-[1rem] lg:gap-[3rem] px-[2rem] lg:px-[8rem]">
        <MediumPadding>
          <div className="flex justify-start 2xl:justify-center max-w-[1600px]">
            <div className="flex justify-center lg:justify-start 2xl:justify-center text-center">
              <h3 className="font-[600] text-primary">{title}</h3>
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center items-center gap-[2rem] md:gap-0 lg:gap-[3.5rem] h-auto">
            <img
              className="w-[300px] md:w-[52vw] lg:w-[500px] wow fadeInUp"
              src={img}
              alt="a"
            />
            <div className="px-[1.5rem] md:px-[2rem] pt-[1rem] md:pt-[4rem] wow fadeInUp">
              <ConfigProvider
                theme={{
                  components: {
                    Timeline: {
                      dotBg: null,
                      lineWidth: 0,
                      lineWidthBold: 3,
                      colorSplit: null,
                      paddingXXS: 0,
                    },
                  },
                }}
              >
                <Timeline
                  mode="left"
                  items={[
                    {
                      dot: <Dot number={1} />,
                      children: (
                        <p className="text-[16px] md:text-[18px]">{one}</p>
                      ),
                    },
                    {
                      dot: <Dot number={2} />,
                      children: (
                        <p className="text-[16px] md:text-[18px]">{two}</p>
                      ),
                    },
                    {
                      dot: <Dot number={3} />,
                      children: (
                        <p className="text-[16px] md:text-[18px]">{three}</p>
                      ),
                    },
                    {
                      dot: <Dot number={4} />,
                      children: (
                        <p className="text-[16px] md:text-[18px]">{four}</p>
                      ),
                    },
                    {
                      dot: <Dot number={5} />,
                      children: (
                        <p className="text-[16px] md:text-[18px]">{five}</p>
                      ),
                    },
                  ]}
                />
              </ConfigProvider>
            </div>
          </div>

          <div className="absolute bg-[#0A7379] h-[10px] bottom-[-5px] right-0 w-full" />
        </MediumPadding>
      </div>
      <img className="w-full mb-[4rem]" src={footerMountain} />
    </>
  );
};

const Dot = ({ number }) => {
  return (
    <div className="h-[30px] w-[30px] rounded-full bg-[#005151] flex justify-center items-center">
      <p className="text-white text-[12px] md:text-[20px]">{number}</p>
    </div>
  );
};

export default SteptoStep;
