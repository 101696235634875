import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

import Footer from '../layouts/Footer';
import DownloadApp from '../components/pages/common/DownloadApp';
import Container from '../components/pages/common/Container';
import SteptoStep from '../components/pages/common/SteptoStep';
import TextBannerBackground from '../components/pages/common/TextBannerBackground';
import H1 from '../components/typography/H1';
import HandleScroll from '../util/functions/handleScroll';
import ComponentMountain from '../components/pages/common/ComponentMountain';

import back from '../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdybacker-empacando-producto-compra-usa.svg';
import shopper from '../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/png/birdybuyer-buscando-productos-compra-usa.png';
import user from '../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdybuyer-buscando-productos-online-usa.svg';
import P from '../components/typography/P';
import ContactUsFAB from '../components/ui/ContactUsFAB';
import BirdyTooltip from '../components/ui/BirdyTooltip';

const Shopper = () => {
  useEffect(() => {
    return () => {
      HandleScroll();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Birdybuyer</title>
        <meta name='description' content='Birdybuyer - comprador' />
      </Helmet>
      <ContactUsFAB />

      <div className="bg-uno bg-center bg-cover flex justify-start px-[2rem] lg:px-[8rem] h-[90vh]">
        <div className="flex flex-col justify-end mb-[4rem] gap-[2rem]">
          <H1 className="text-white font-[700] w-[18rem] md:w-[35rem] leading-[2.5rem] md:leading-[3.5rem] lg:leading-[4.5rem]">
            {t(
              'Con nosotros, es muy fácil traer tus productos favoritos desde afuera.',
            )}
          </H1>

          <p className="text-white text-[20px] leading-[30px] w-[16rem] md:w-[25rem]">
            {t('Te ayudamos a')}{' '}
            <span className="font-[700]">
              {t('traer a Colombia todo lo que necesites de Estados Unidos')}
            </span>
            {'; '}
            {t('y lo mejor es que no nos complicamos.')}
          </p>
        </div>
      </div>

      <Container
        title="¡Hola, Birdybuyer!"
        // subtitle="¡Un Birdybacker te lo trae!"
        text={
          <>
            {t('Hacemos llegar')}{' '}
            <span className="font-[700]">
              {t(
                'tu pedido a Colombia, desde cualquier tienda física con presencia online en Estados Unidos',
              )}
            </span>
            {'.'}
          </>
        }
        text2={
          <>
            {t(
              'Y por si las moscas, lo hacemos de manera confiable con la ayuda de nuestros Birdybackers.',
            )}
          </>
        }
        buttonText="BIRDYBUYER"
        image={shopper}
        alt="Birdybuyer (comprador) buscando productos en linea en Estados Unidos para comprarlos a traves de Birdyback"
        to="/signup"
      />

      <ComponentMountain
        img={user}
        alt="Birdybuyer (comprador) buscando productos en linea en Estados Unidos para comprarlos a traves de Birdyback"
        title={
          <>
            <h2 className="font-[700] max-w-[30rem] text-primary leading-[2.5rem] md:leading-[3.5rem]">
              {t('¿Ya sabes qué vas a comprar?')}
            </h2>

            <div className="h-[1rem]" />

            <h5 className="mt-[1rem] md:mt-0 text-primary">
              {t('¡Un Birdybacker te lo trae!')}
            </h5>
          </>
        }
        text={
          <>
            <P className="w-[18rem] lg:w-[24rem]">
              {t('Sabemos que en Estados Unidos puedes encontrar')}{' '}
              <span className="font-[700]">
                {t(
                  'el producto que necesitas, muchísimo más económico y de excelente calidad',
                )}
              </span>
              {'.'}
            </P>

            <br />

            <P className="hidden md:block mb-3 md:mb-0 w-[18rem] lg:w-[24rem]">
              {t('Por eso, te ayudamos a traerlo hasta tus manos.')}
            </P>
          </>
        }
        textButton="¡QUIERO COMPRAR!"
      />

      <SteptoStep
        img={back}
        title={t('¿Quieres ser un Birdybuyer? Así puedes empezar:')}
        alt="Birdybacker (viajero) empacando en su maleta de vuelo los productos comprador por el Birdybuyer (comprador) desde Colombia en Estados Unidos"
        one={
          <>
            <Link
              onClick={HandleScroll}
              to="/signup"
              className="font-[700] underline"
            >
              {t('Registrate')}
            </Link>
            {', '}
            {t('o')}{' '}
            <Link
              onClick={HandleScroll}
              to="/signup"
              className="font-[700] underline"
            >
              {t('inicia sesión')}
            </Link>
            {'.'}
          </>
        }
        two={
          <>
            <span className="font-[700]">
              {t('Dinos cuántos productos quieres comprar')}
            </span>
            {'; '}
            {t('puedes')}{' '}
            <span className="underline">
              {t('pedir todos los que quieras.')}
            </span>
          </>
        }
        three={
          <>
            <span className="font-[700]">
              <span className="underline">{t('Tú decides')}</span>
              <BirdyTooltip text="¡Piensa un monto de dinero para que tu Birdybacker pueda ganar bien por traer tu encargo!" />{' '}
              {t('cuánto pagarle a un Birdybacker')}
            </span>{' '}
            {t('por traer tu encargo.')}
          </>
        }
        four={
          <>
            <span className="font-[700]">{t('Paga')}</span>{' '}
            {t('tu compra y la')}{' '}
            <span className="underline">
              {t('oferta para tu Birdybacker.')}
            </span>
            <BirdyTooltip text="Tu dinero está seguro; le entregamos el dinero a tu Birdybacker cuando hayas recibido la compra satisfactoriamente." />
          </>
        }
        five={
          <>
            <span className="font-[700]">{t('Recibe tu producto')}</span>{' '}
            {t('en el tiempo establecido por el')}{' '}
            <span className="underline">
              {t('panel de Momentos del Trayecto.')}
            </span>
          </>
        }
      />

      <TextBannerBackground>
        {t('Acá estamos para darte soluciones; para que puedas hacer tu')}{' '}
        <span className="font-[700]">
          {t(
            'compra sin levantarte de la silla y recibas tu producto en menos de lo que canta un gallo',
          )}
          .
        </span>
      </TextBannerBackground>

      <div className="mb-[4rem]" />

      {/* <DownloadApp /> */}

      <Footer text="¡Queremos conectar contigo!" />
    </>
  );
};

export default Shopper;
