import React, { useEffect, useState } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { NavLink, Outlet } from 'react-router-dom';
import main from '../assets/js/main';
import logo from '../assets/BirdybackDesign/Logo/03-SVG/logo-birdyback-sin-fuente.svg';
import ModalLogin from '../components/ui/ModalLogin';
import { useTranslation } from 'react-i18next';
import NavItem from '../components/nav/NavItem';
import NavItemResponsive from '../components/nav/NavItemResponsive';
import HandleScroll from '../util/functions/handleScroll';
import ModalSuscription from '../components/ui/ModalSuscription';

function classNames (...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavNormal = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    main();
  }, []);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const [modalOn, setModalOn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {modalOpen && (
        <ModalSuscription modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )}

      {/* {modalOn && <ModalLogin modalOn={modalOn} setModalOn={setModalOn} />} */}

      <header
        className="header sticky top-[-2px] left-0 z-[1] flex w-full items-center"
        // className="header absolute sticky bg-white top-[-2px] left-0 z-[1] flex w-full items-center"
        style={
          mobileMenuOpen
            ? { transition: 'top 0.5s', display: 'none' }
            : { transition: 'top 0.5s' }
        }
      >
        <nav
          className="nav-shadow flex w-full items-center justify-between px-[1rem] py-[11px] xl:px-[8rem] shadow-lg"
          // className="nav-shadow flex w-full items-center justify-between px-[1rem] py-[11px] xl:px-[7rem] shadow-lg"
          aria-label="Global"
        >
          <NavLink to="/" onClick={HandleScroll}>
            <img
              src={logo}
              alt="Logo de Birdyback sin fuentes"
              className="nav-item w-[55px] md:w-[65px]"
            />
          </NavLink>

          <button
            type="button"
            className="block xl:hidden -m-2.5 items-center justify-center rounded-md p-2.5 text-primary"
            onClick={() => setMobileMenuOpen(true)}
          >
            {mobileMenuOpen ? (
              <XMarkIcon className="w-[50px]" aria-hidden="true" />
            ) : (
              <Bars3Icon className="w-[50px]" aria-hidden="true" />
            )}
          </button>

          <Popover.Group className="hidden xl:flex lg:gap-x-8 items-center">
            <NavItem redirect="/" text="Inicio" />

            <NavItem redirect="/nosotros" text="Nosotros" />

            <NavItem redirect="/birdybuyer" text="Birdybuyer" />

            <NavItem redirect="/birdybacker" text="Birdybacker" />

            <NavItem redirect="/seguridad" text="Seguridad" />

            <NavItem redirect="/faqs" text="FAQ" />

            {/* <NavItem redirect="/blog" text="Blog" /> */}

            {/* <Popover className="relative">
              <Popover.Button className="flex items-center gap-x-1 text-[16px] font-medium leading-6 text-primary font-Montserrat">
                {t('lan')}
                <ChevronDownIcon
                  className="h-5 w-5 flex-none text-primary font-Montserrat"
                  aria-hidden="true"
                />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-[10rem] max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-primary/5">
                  <div className="p-4">
                    <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                      <div className="flex-auto">
                        <button
                          className="block font-semibold text-primary font-Montserrat"
                          onClick={() => i18n.changeLanguage('en')}
                        >
                          {t('Inglés')}
                          <span className="absolute inset-0" />
                        </button>
                      </div>
                    </div>
                    <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50">
                      <div className="flex-auto">
                        <button
                          className="block font-semibold text-primary font-Montserrat"
                          onClick={() => i18n.changeLanguage('es')}
                        >
                          {t('Español')}
                          <span className="absolute inset-0" />
                        </button>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover> */}

            <button
              className="hidden xl:block text-primary bg-secondary rounded-[50px] py-[0.6rem] md:py-[0.8rem] p-[1.2rem] md:p-[1.6rem] font-Montserrat font-[700] text-[10px] md:text-[14px] shadow-lg hover:text-secondary hover:bg-primary"
              // onClick={() => setModalOn(true)}
              onClick={() => setModalOpen(true)}
            >
              <p className="text-[16px] font-[600]">
                {t('¡QUIERO SER PARTE!')}
              </p>
            </button>
          </Popover.Group>
        </nav>
        <Dialog
          as="div"
          className="xl:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 top-0 right-0 z-10 w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between px-[1rem] py-[10px]">
              <NavLink to="/" onClick={HandleScroll}>
                <img src={logo} alt="logo" className="w-[55px]" alt="Logo de Birdyback sin fuentes" />
              </NavLink>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <XMarkIcon className="w-[50px]" aria-hidden="true" />
              </button>
            </div>
            <div className="flow-root px-6">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-12">
                  <NavItemResponsive
                    redirect="/"
                    text="Inicio"
                    setMobileMenuOpen={setMobileMenuOpen}
                  />

                  <NavItemResponsive
                    redirect="/nosotros"
                    text="Nosotros"
                    setMobileMenuOpen={setMobileMenuOpen}
                  />

                  <NavItemResponsive
                    redirect="/birdybuyer"
                    text="Birdybuyer"
                    setMobileMenuOpen={setMobileMenuOpen}
                  />

                  <NavItemResponsive
                    redirect="/birdybacker"
                    text="Birdybacker"
                    setMobileMenuOpen={setMobileMenuOpen}
                  />

                  <NavItemResponsive
                    redirect="/seguridad"
                    text="Seguridad"
                    setMobileMenuOpen={setMobileMenuOpen}
                  />

                  <NavItemResponsive
                    redirect="/faqs"
                    text="FAQ"
                    setMobileMenuOpen={setMobileMenuOpen}
                  />

                  {/* <NavItemResponsive
                    redirect="/blog"
                    text="Blog"
                    setMobileMenuOpen={setMobileMenuOpen}
                  /> */}

                  {/* <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50">
                          {t('lan')}
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'rotate-180' : '',
                              'h-5 w-5 flex-none',
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          <Disclosure.Button
                            as="button"
                            onClick={() => i18n.changeLanguage('en')}
                            className="block rounded-lg py-2 text-sm font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50 w-full"
                          >
                            {t('Inglés')}
                          </Disclosure.Button>
                          <Disclosure.Button
                            as="button"
                            onClick={() => i18n.changeLanguage('es')}
                            className="block rounded-lg py-2 text-sm font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50 w-full"
                          >
                            {t('Español')}
                          </Disclosure.Button>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure> */}
                </div>

                {/*            <div className="py-6">
                  <NavItemResponsive
                    redirect="/login"
                    text="signIn"
                    setMobileMenuOpen={setMobileMenuOpen}
                  />

                  <NavItemResponsive
                    redirect="/signup"
                    text="signUp"
                    setMobileMenuOpen={setMobileMenuOpen}
                  />
                </div> */}
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <Outlet />
    </>
  );
};

export default NavNormal;
