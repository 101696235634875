import React from 'react';

import Section from '../../ui/Section';
import LargePadding from '../../ui/LargePadding';

import largeCenteredOrangeCloud from '../../../assets/background/nube-grande-centrada-naranja-birdybuyer.svg';
import smallRightOrangeCloud from '../../../assets/background/nube-pequeña-derecha-naranja-birdybuyer.svg';

const TextBannerBackground = ({ children }) => {
  return (
    <Section>
      <LargePadding>
        <img
          className="absolute w-[240px] md:w-auto top-[10vh] md:top-[5vh] lg:top-[10vh] left-[10vw] z-[-1]"
          src={largeCenteredOrangeCloud}
          alt="Nube centrada grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
        />

        <img
          className="absolute w-[160px] md:w-auto bottom-0 right-0 z-[-1]"
          src={smallRightOrangeCloud}
          alt="Nube derecha pequeña naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
        />

        <div className="flex justify-center items-center px-[2rem] md:px-0 gap-[1rem] md:gap-[2rem] h-full w-full">
          <div className="w-[3px] h-[120px] lg:h-[215px] bg-[#C6D12D] wow fadeInUp" />
          <h3 className="font-[300] text-primary max-w-[40rem] lg:max-w-[57rem] wow fadeInUp">
            {children}
          </h3>
        </div>
      </LargePadding>
    </Section>
  );
};

export default TextBannerBackground;
