import React from 'react';

import Section from '../../ui/Section';

import largeRightCloud from '../../../assets/background/nube-grande-derecha-azul-birdybacker.svg';

const ContainerSecurityBackground = ({ children }) => {
  return (
    <Section h="h-full">
      <img
        className="absolute w-[240px] md:w-auto top-[20vh] md:top-[40vh] lg:top-[30vh] right-0 z-[-1]"
        src={largeRightCloud}
        alt="Nube derecha grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {children}
    </Section>
  );
};

export default ContainerSecurityBackground;
