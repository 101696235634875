import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

import { t } from 'i18next';
import { Link } from 'react-router-dom';

import Footer from '../layouts/Footer';
import DownloadApp from '../components/pages/common/DownloadApp';
import Container from '../components/pages/common/Container';
import SteptoStep from '../components/pages/common/SteptoStep';
import TextBannerBackground from '../components/pages/common/TextBannerBackground';
import HandleScroll from '../util/functions/handleScroll';
import ComponentMountain from '../components/pages/common/ComponentMountain';
import H1 from '../components/typography/H1';

import traveler from '../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/png/birdybacker-esperando-producto-envio-birdyback.png';
import travelering from '../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/png/birdybacker-ganando-dinero-cambio-moneda-envio-producto.png';
import checkList from '../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/checklist-birdybacker-registrar-vuelo-app-birdyback.svg';
import P from '../components/typography/P';
import ContactUsFAB from '../components/ui/ContactUsFAB';
import BirdyTooltip from '../components/ui/BirdyTooltip';

const Traveler = () => {
  useEffect(() => {
    return () => {
      HandleScroll();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Birdybacker</title>
        <meta name='description' content='Birdybacker - viajero' />
      </Helmet>

      <ContactUsFAB />

      <div className="bg-dos bg-center bg-cover flex justify-end px-[2rem] lg:px-[8rem] h-[90vh]">
        <div className="flex flex-col justify-end mb-[4rem] gap-[2rem]">
          <H1 className="font-[700] max-w-[300px] md:max-w-[30rem] text-white leading-[2.5rem] md:leading-[3.5rem] lg:leading-[4.5rem]">
            {t('Con nosotros, ganas dinero viajando.')}
          </H1>

          <p className="text-white text-[20px] leading-[30px] w-[300px] md:w-[25rem]">
            {t('Queremos que ganes')}{' '}
            <span className="font-[700]">
              {t('dinero mientras viajas de Estados Unidos a Colombia')}
            </span>
            {'. '}
            {t('¡Acá todos nos apoyamos con la mejor actitud!')}
          </p>
        </div>
      </div>

      <Container
        title="¿Vas a viajar pronto?"
        subtitle="¡Puedes ser un Birdybacker!"
        text={
          <>
            {t('Si viajas desde Estados Unidos a Colombia,')}{' '}
            <span className="font-[700]">
              {t(
                'alquila un espacio de tu maleta (o déjanos llenarla toda por ti) para llevar el pedido de un Birdybuyer, y gana dinero por hacerlo',
              )}
            </span>
            {'.'}
          </>
        }
        text2={<>{t('Así de fácil, o mejor dicho, ¡papita!')}</>}
        buttonText="Birdybacker"
        image={traveler}
        alt="Birdybacker (viajero) esperando tranquilamente que Birdyback le empareje un producto de un birdybuyer (comprador) para enviarlo desde Estados Unidos hacia Colombia"
        reversedImage={true}
        to="/signup"
      />

      {/* sin comas */}
      <ComponentMountain
        img={checkList}
        alt="Birdybacker (viajero) revisando el checklist de información para registrar el vuelo y empezar a enviar productos desde Estados Unidos a Colombia a traves de Birdyback"
        title={
          <h2 className="font-[600] max-w-[30rem] text-primary leading-[3.5rem]">
            {t('¿Tienes este checklist completo?')}
          </h2>
        }
        text={
          <>
            <P className="mb-3 md:mb-0 w-[18rem] lg:w-[24rem]">
              {t('¿Ves? Es muy')}{' '}
              <span className="font-[700]">
                {t('fácil ganar dinero mientras viajas')}
              </span>
              {', '}
              {t('¡ojalá todos los checklists fueran así de rápidos!')}
            </P>

            <br className="hidden md:block" />

            <P className="mb-3 md:mb-0 w-[18rem] lg:w-[24rem]">
              {t(
                'Nosotros no te vamos a pedir más nada, solo eso. ¡Sin miedo al éxito!',
              )}
            </P>

            <br className="hidden md:block" />

            <P className="hidden md:block mb-3 md:mb-0 font-[700] w-[18rem] lg:w-[24rem]">
              {t('¿Te animas?')}
            </P>
          </>
        }
        textButton="¡SÍ, VOY A VIAJAR!"
        traveler
      />

      <SteptoStep
        img={travelering}
        alt="Birdybacker (viajero) ganando dinero cambiando su dinero y enviando productos desde Estados Unidos hacia Colombia a través de Birdyback"
        title={t('¿Quieres ser un Birdybacker? Así puedes empezar:')}
        one={
          <>
            {/* <Link
              to="/signup"
              onClick={HandleScroll}
              className="font-[700] underline"
            > */}
            <span className="font-[700]">{t('Regístrate')}</span>
            {/* </Link>*/}
            {t(' o ')}
            {/* <Link
              onClick={HandleScroll}
              to="/signup"
              className="font-[700] underline"
            > */}
            <span className="font-[700]">{t('inicia sesión')}</span>
            {/* </Link> */}
            {'.'}
          </>
        }
        two={
          <>
            <span className="font-[700]">
              {t('Ingresa los datos de tu vuelo')}
            </span>
            {', '}
            {t('y dinos si')}{' '}
            <span className="font-[700]">
              {t('quieres')}{' '}
              <span className="underline">
                {t('llevar un objeto o una maleta')}
              </span>
            </span>{' '}
            {t('para que nosotros la llenemos por ti.')}
          </>
        }
        three={
          <>
            <span className="font-[700]">
              {t('Activa tus notificaciones para que seas')}{' '}
              <span className="underline">{t('el primer Birdybacker')}</span>
            </span>{' '}
            {t('en aceptar la')}{' '}
            <span className="underline">{t('oferta')}</span>
            <BirdyTooltip text="Acepta la que más se acomode a tus necesidades; también, puedes hacer una contraoferta." />{' '}
            {t('que te guste.')}
          </>
        }
        four={
          <>
            <span className="font-[700]">{t('Haz la compra')}</span>{' '}
            {t('de tu Birdybuyer,')}{' '}
            <span className="font-[700]">{t('empácala')}</span>{' '}
            {t('y cuando llegues a Colombia,')}{' '}
            <span className="font-[700]">{t('envíala a su dirección')}</span>
            {'.'}
          </>
        }
        five={
          <>
            <span className="font-[700]">{t('Recibe tu dinero')}</span>{' '}
            <span className="underline">
              {t('completo, y un montoncito más.')}
            </span>
          </>
        }
      />

      <TextBannerBackground>
        <span className="font-[700]">
          {t('¡Es muy muy fácil ganar dinero extra mientras viajas!')}
        </span>{' '}
        {t(
          'Además, en Birdyback te damos buenas alternativas para cambiar tus divisas.',
        )}
      </TextBannerBackground>

      <div className="mb-[4rem]" />

      {/* <DownloadApp /> */}

      <Footer text="¡Queremos conectar contigo!" />
    </>
  );
};

export default Traveler;
