import React, { useState } from 'react';

import { t } from 'i18next';
import { ConfigProvider, FloatButton } from 'antd';
import { CommentOutlined } from '@ant-design/icons';

import ModalRegisterEmail from './ModalRegisterEmail';

const ContactUsFAB = () => {
  const [modalEmailIsOpen, setModalEmailIsOpen] = useState(false);

  return (
    <>
      {modalEmailIsOpen && (
        <ModalRegisterEmail
          open={modalEmailIsOpen}
          setOpen={setModalEmailIsOpen}
        />
      )}

      <ConfigProvider
        theme={{
          components: {
            FloatButton: {
              colorBgElevated: '#000',
              colorFillContent: '#000',
              colorFillContentHover: '#000',
              paddingXXS: 8,
            },
          },
        }}
      >
        <FloatButton
          icon={
            <div className="flex justify-center items-center">
              <CommentOutlined
                onClick={() => setModalEmailIsOpen(true)}
                className="text-white"
                style={{ fontSize: '28px' }}
              />
            </div>
          }
          tooltip={<p className="text-[16px]">{t('Contáctanos')}</p>}
          style={{ width: 64, height: 64 }}
          onClick={() => setModalEmailIsOpen(true)}
        />
      </ConfigProvider>
    </>
  );
};

export default ContactUsFAB;
