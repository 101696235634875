import React from 'react';

import { t } from 'i18next';

import RoundedButton from '../../ui/RoundedButton';
import P from '../../typography/P';

const CardSecurity = ({ title, description, right = false, redirect }) => {
  return (
    <div
      className={
        right
          ? 'flex flex-col gap-[1rem] my-[2rem] mr-[3rem] lg:mr-[8rem] ml-[3rem]'
          : 'flex flex-col gap-[1rem] my-[2rem] ml-[3rem] lg:ml-[8rem] mr-[3rem]'
      }
    >
      <div className="bg-[#FFD2B040] flex flex-col justify-between gap-[1rem] p-[1rem] md:p-[2rem] rounded-[13px] md:rounded-[30px] h-full">
        <h5 className="text-[20px] md:text-[22px] font-[700] w-[16rem] md:w-[14rem] lg:w-[20rem] lg:h-[66px] text-[#3FC2A0]">
          {t(title)}
        </h5>

        <P>{t(description)}</P>

        {/* <RoundedButton className="pt-[1rem]" text="Revisar" /> */}
      </div>
    </div>
  );
};

export default CardSecurity;
