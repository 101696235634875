import { createSlice } from "@reduxjs/toolkit";

let initialState = [];

if (localStorage.getItem("auth")) {
  initialState = [
    {
      mainAddress: true,
      department: "Caldas",
      description: "golpear dos veces porque el timbre no funciona",
      kindStreet: "Calle",
      id: "1",
      location: "Villamaria",
      neighborhood: "La Pradera",
      number: false,
      numberStreet: "14A",
      numberStreet2: "7",
      place: "home",
      street: "22 sur",
    },
    {
      mainAddress: false,
      id: "2",
      department: "Caldas",
      location: "Manizales",
      neighborhood: "Santarosa",
      kindStreet: "Calle",
      street: "16",
      numberStreet: "18B",
      numberStreet2: "21",
      number: false,
      place: "work",
      description: "entregarlo al vigilante",
    },
    {
      mainAddress: false,
      department: "Caldas",
      description: "golpear dos veces porque el timbre no funciona",
      kindStreet: "Calle",
      id: "3",
      location: "Villamaria",
      neighborhood: "La Pradera",
      number: false,
      numberStreet: "14A",
      numberStreet2: "7",
      place: "home",
      street: "22 sur",
    },
  ];
}

export const userAddresses = createSlice({
  name: "userAddresses",
  initialState,
  reducers: {
    addAddress: (state, action) => {
      state.push(action.payload);
    },
    updateMainAddress: (state, action) => {
      const address = state.find((address) => address.mainAddress === true);
      const newMainAddress = state.find(
        (address) => address.id === action.payload
      );
      if (address && address.id !== action.payload) {
        address.mainAddress = false;
        newMainAddress.mainAddress = true;
      }
    },
    updateAddress: (state, action) => {
      const {
        department,
        description,
        kindStreet,
        id,
        location,
        neighborhood,
        number,
        numberStreet,
        numberStreet2,
        place,
        street,
      } = action.payload;
      const address = state.find((address) => address.id === id);
      if (address) {
        address.department = department;
        address.description = description;
        address.kindStreet = kindStreet;
        address.location = location;
        address.neighborhood = neighborhood;
        address.number = number;
        address.numberStreet = numberStreet;
        address.numberStreet2 = numberStreet2;
        address.place = place;
        address.street = street;
      }
    },
    deleteAddress: (state, action) => {
      const address = state.find((address) => address.id === action.payload);
      if (address) {
        state.splice(state.indexOf(address), 1);
      }
    },
  },
});

export const { addAddress, updateMainAddress, updateAddress, deleteAddress } =
  userAddresses.actions;
export default userAddresses.reducer;
