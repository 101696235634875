import axios from './axios';

export const productsApi = {
  path: '/products',
  async create_product(data) {
    console.log(data);
    console.log(JSON.parse(localStorage.getItem('birdyuser')));
    return await axios.post(`${this.path}`, data, {
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
  async update_product(data) {
    const { id, values } = data;
    return await axios.put(`${this.path}/${id}`, {
      data: { ...values },
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
  async list_products() {
    return await axios.get(`${this.path}`, {
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
  async get_one(data) {
    const { id } = data;
    return await axios.get(`${this.path}/${id}`, {
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
  async delete_product(data) {
    const { id } = data;
    return await axios.delete(`${this.path}/${id}`, {
      headers: { Authorization: JSON.parse(localStorage.getItem('birdyuser')) },
    });
  },
};
