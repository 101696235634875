import React from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';

const RoundedButton = ({
  className,
  classNameButton = null,
  text,
  type,
  redirect,
  onClick,
  wow = true,
}) => {
  return (
    <Link className={className} onClick={onClick} to={redirect}>
      <button
        type={type}
        className={`text-primary bg-secondary rounded-[50px] py-[0.6rem] md:py-[0.8rem] p-[1.2rem] md:p-[1.6rem] font-Montserrat font-[700] text-[10px] md:text-[14px] shadow-lg hover:text-secondary hover:bg-primary
        ${classNameButton} ${wow ? 'wow fadeInUp' : null}`}
      >
        <p className="font-[600]">{t(text)}</p>
      </button>
    </Link>
  );
};

export default RoundedButton;
