import { t } from "i18next";
import React from "react";
import { NavLink } from "react-router-dom";
import HandleScroll from "../../util/functions/handleScroll";

const NavItem = ({ redirect, text, className }) => {
  return (
    <NavLink
      onClick={HandleScroll}
      className={`text-[16px] font-medium leading-6 text-primary font-Montserrat ${className}`}
      to={t(redirect)}
    >
      {t(text)}
    </NavLink>
  );
};

export default NavItem;
