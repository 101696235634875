import React from 'react';

import { Tooltip } from 'antd';
import { t } from 'i18next';

import { InfoCircleTwoTone } from '@ant-design/icons';

const BirdyTooltip = ({ text }) => {
  return (
    <Tooltip title={t(text)}>
      <InfoCircleTwoTone
        twoToneColor="#3FC2A0"
        // style={{ fontSize: '12px' }}
      />
    </Tooltip>
  );
};

export default BirdyTooltip;
