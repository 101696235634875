import React, { useState } from 'react';
import * as Yup from 'yup';

const ResponsiveInputEmail = ({ value, onChange, handleButtonClick }) => {
  const [error, setError] = useState('');

  const handleEmailChange = (e) => {
    onChange(e);
    setError('');
  };

  const handleClick = () => {
    const validationSchema = Yup.object().shape({
      email: Yup.string()
        .email('Ingresa un correo electrónico válido')
        .required('El correo electrónico es requerido'),
    });

    validationSchema
      .validate({ email: value })
      .then(() => {
        if (handleButtonClick) {
          handleButtonClick();
        }
      })
      .catch((err) => {
        setError(err.errors[0]);
      });
  };

  return (
    <>
      <div className="bg-white mx-4 shadow-lg flex items-center my-[1rem] rounded-[50px] overflow-hidden">
        <input
          className="font-Montserrat placeholder:text-primary text-[14px] px-[1.5rem] py-[0.5rem] w-full md:w-[430px] focus:outline-none"
          placeholder="Correo electrónico"
          value={value}
          onChange={handleEmailChange}
        />
        <button
          className="bg-secondary font-Montserrat text-[14px] md:text-[16px] text-primary font-[600] px-[0.8rem] md:px-[1.5rem] py-[0.5rem]"
          onClick={handleClick}
        >
          ¡DESPEGUEMOS!
        </button>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </>
  );
};

export default ResponsiveInputEmail;
