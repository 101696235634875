import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';

import Footer from '../layouts/Footer';
import FirstSection from '../components/pages/security/FirstSection';
import SecondSection from '../components/pages/security/SecondSection';
import CommitmentsContainer from '../components/pages/common/CommitmentsContainer';
import TextSlide from '../components/pages/security/TextSlide';
import Container from '../components/pages/security/Container';
import OurCommitment from '../components/pages/security/OurCommitment';
import H1 from '../components/typography/H1';
import HandleScroll from '../util/functions/handleScroll';
import ContactUsFAB from '../components/ui/ContactUsFAB';

const Security = () => {
  useEffect(() => {
    return () => {
      HandleScroll();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Seguridad</title>
        <meta name='description' content='Seguridad' />
      </Helmet>
      <ContactUsFAB />

      <div className="bg-bench bg-cover bg-center md:bg-start flex px-[2rem] md:px-[3rem] lg:px-[8rem] h-[90vh]">
        <div className="flex flex-col justify-end h-full pb-[5rem] md:pb-[4rem] gap-[2rem]">
          <H1 className="font-[500] text-white w-[18rem] md:w-[30rem] leading-[2.5rem] md:leading-[3.5rem] lg:leading-[4.5rem]">
            {t('¡Tu seguridad es nuestra prioridad!')}
          </H1>

          <p className="text-white text-[20px] leading-[30px] w-[16rem] md:w-[25rem]">
            {t('Si tú estás bien, nosotros también.')}
          </p>
        </div>
      </div>

      <Container />

      <FirstSection />

      <OurCommitment />

      <TextSlide />

      <CommitmentsContainer />

      <Footer text="¡Queremos conectar contigo!" />
    </>
  );
};

export default Security;
