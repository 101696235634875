import React, { useEffect, useRef } from 'react';

import { t } from 'i18next';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import './parallax.css';

import ParallaxItem from './ParallaxItem';
import ParallaxPadding from '../../ui/ParallaxPadding';

import one from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdybuyer-precios-caros-colombia-baratos-usa.svg';
import two from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdybuyer-buscando-envio-producto-usa.svg';
import three from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdyback-nacimiento-ideacion-madrugada.svg';
import four from '../.././../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdybacker-birdybuyer-uniendo-esfuerzos-celebrando.svg';
import P from '../../typography/P';
import HandleScroll from '../../../util/functions/handleScroll';

const Parallax = () => {
  const containerRef = useRef();

  // console.log(containerRef?.current?.offsetWidth);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const width = window.innerWidth;

    const panels = gsap.utils.toArray('.panel_parallax');

    const animation = gsap.context(() => {
      gsap.to(panels, {
        // xPercent: -100 * (panels.length - 1),
        xPercent: width > 768 ? -100 * (panels.length - 1) : undefined,
        yPercent: width <= 768 ? -100 * (panels.length - 1) : undefined,
        ease: 'none',
        scrollTrigger: {
          trigger: '.parallax_container',
          // horizontal: true,
          pin: true,
          scrub: true,
          snap: {
            snapTo: 1 / (panels.length - 1),
            inertia: true,
            // duration: { min: 0.5, max: 0.5 },
            delay: 0.2,
          },
          end: () => '5396',
          // onEnter: (self) => {
          //   // self.progress = 0;
          //   // self.direction = 0;
          //   // self.disable();
          //   // self.enable();
          //   // self.animation.pause();
          //   setTimeout(() => {
          //     self.refresh();
          //   }, 500);
          //   // self.refresh();
          //   // isActive = false;
          //   // console.log('enter', self.progress, self.direction, self.isActive);
          // },
        },
      });
    });

    return () => {
      animation.revert();
      HandleScroll();
    };
  }, []);

  return (
    <section className="parallax">
      <div ref={containerRef} className="parallax_container">
        <ParallaxPadding>
          <section className="flex flex-col justify-around lg:justify-between 2xl:justify-evenly w-screen panel_parallax">
            <ParallaxItem text="Nos preocupamos." img={one} alt="Birdybuyer (comprador) buscando productos en Colombia y descubre que son muy caros comparados con Estados Unidos">
              <div className="w-[4px] h-[110px] md:h-[108px] lg:h-[130px] bg-[#F98E19]" />
              <P className="font-[500] w-[80vw] md:w-[28vw] text-[14px] md:text-[20px]">
                {t(
                  'En aquel tiempo, los productos que necesitábamos estaban muy caros (bueno, todavía), y la opción más amigable con nuestro bolsillo era comprarlos en otro país.',
                )}
              </P>
            </ParallaxItem>
          </section>
        </ParallaxPadding>
        <ParallaxPadding>
          <section className="flex flex-col justify-around lg:justify-between 2xl:justify-evenly w-screen panel_parallax">
            <ParallaxItem text="Sí, también nos pasó." img={two} alt="Birdybuyer (comprador) buscando alguien que le envie y transporte un producto comprado en Estados Unidos hacia Colombia">
              <div className="w-[4px] h-[90px] md:h-[128px] lg:h-[96px] bg-[#F98E19]" />
              <P className="font-[500] w-[80vw] md:w-[28vw] text-[14px] md:text-[20px]">
                {t(
                  'Nos dimos cuenta de que no éramos los únicos tratando de encontrar a alguien que pudiese traernos un encargo desde afuera.',
                )}
              </P>
            </ParallaxItem>
          </section>
        </ParallaxPadding>
        <ParallaxPadding>
          <section className="flex flex-col justify-around lg:justify-between 2xl:justify-evenly w-screen panel_parallax">
            <ParallaxItem text="Encontramos nuestro propósito." img={three} alt="Birdyback nace en la madrugada a las 3:00AM y empieza la aventura">
              <div className="w-[4px] h-[110px] md:h-[108px] lg:h-[96px] bg-[#F98E19]" />
              <P className="font-[500] w-[80vw] md:w-[28vw] text-[14px] md:text-[20px]">
                {t('Se nos ocurrió preguntarnos, ¿qué tal si')}{' '}
                <span className="font-[700]">
                  {t('ayudamos a los demás a traer sus productos favoritos')}
                </span>
                {t(' ? Y por ahí derecho, nos ayudamos a nosotros mismos.')}
              </P>
            </ParallaxItem>
          </section>
        </ParallaxPadding>
        <ParallaxPadding>
          <section className="flex flex-col justify-around lg:justify-between 2xl:justify-evenly w-screen panel_parallax">
            <ParallaxItem text="¡Nació Birdyback!" img={four} alt="Birdybuyer (comprador) y Birdybacker (viajero) uniendo esfuerzos para comprar productos en Estados Unidos y enviarlos y transportarlos hacia Colombia">
              <div className="w-[4px] h-[90px] md:h-[128px] lg:h-[72px] bg-[#F98E19]" />
              <P className="font-[500] w-[80vw] md:w-[28vw] text-[14px] md:text-[20px]">
                {t(
                  'Trabajamos con excelencia para ser los mejores en lo que hacemos: conectar personas con sus productos favoritos.',
                )}
              </P>
            </ParallaxItem>
          </section>
        </ParallaxPadding>
      </div>
    </section>
  );
};

export default Parallax;
