import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
  Input,
  IconButton,
  Typography,
  Radio,
} from '@material-tailwind/react';
import Highlighter from 'react-highlight-words';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';

import logo from '../assets/logos/logo-birdyback-sin-fuente.png';
import Footer from '../layouts/Footer';
import QABackground from '../components/pages/QA/QABackground';
import H1 from '../components/typography/H1';
import MediumPadding from '../components/ui/MediumPadding';
import { tableQuestions0 } from '../constants/FAQs';
import HandleScroll from '../util/functions/handleScroll';
import ContactUsFAB from '../components/ui/ContactUsFAB';
import P from '../components/typography/P';

function Icon ({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${id === open ? 'rotate-180' : ''
        } h-5 w-5 transition-transform text-[#3FC2A0]`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const QA = () => {
  const [open, setOpen] = useState(-1);
  const [questions, setQuestions] = useState(tableQuestions0);
  const [search, setSearch] = useState('');
  const [QA, setQA] = useState('all');
  const [active, setActive] = useState(1);

  useEffect(() => {
    return () => {
      HandleScroll();
    };
  }, []);

  const next = () => {
    if (active === 3) return;
    // get();
    setActive(active + 1);
  };

  const prev = () => {
    if (active === 1) return;
    // get();
    setActive(active - 1);
  };

  const getQA = (QA) => {
    setQA(QA);
    setOpen(-1);
    let result = tableQuestions0;
    if (
      QA === 'Birdybacker' ||
      QA === 'Birdybuyer' ||
      QA === 'Birdycommunity'
    ) {
      result = tableQuestions0.filter((question) =>
        question.category
          .toString()
          .toLowerCase()
          .includes(QA.toString().toLowerCase())
          ? question
          : null,
      );
    }
    setQuestions(result);
  };

  const handleOpen = (value, e) => {
    e.preventDefault(); // Prevent the default behavior
    setOpen(open === value ? -1 : value);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    filterTerm(e.target.value);
  };

  const filterTerm = (searchTerm) => {
    let result = tableQuestions0.filter((question) =>
      question.title
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
        question.body.toString().toLowerCase().includes(searchTerm.toLowerCase())
        ? question
        : null,
    );
    setQuestions(result);
  };

  return (
    <>
      <Helmet>
        <title>FAQs</title>
        <meta name='description' content='Preguntas más frecuentes' />
      </Helmet>

      <ContactUsFAB />

      <QABackground>
        <MediumPadding>
          <div className="flex justify-center h-full">
            <div className="px-[1rem] md:px-[4rem] lg:px-[8rem] max-w-[1600px]">
              <div className="flex flex-col gap-[1.5rem] text-center items-center mb-[1rem] md:mb-[1.5rem]">
                <H1 className="text-primary font-semibold">
                  {t('Preguntas frecuentes')}
                </H1>

                <div className="flex flex-col gap-[1rem] max-w-[1000px]">
                  <h5 className="font-Montserrat font-[300] text-[16px] md:text-[22px]">
                    {t(
                      '¿Tienes preguntas sobre cómo funciona Birdyback? ¿Necesitas una mano para comenzar con tus compras globales o ganancias de viaje? No te preocupes, nosotros estamos aquí para ayudarte.',
                    )}
                  </h5>

                  <h5 className="hidden md:block font-Montserrat font-[300] md:text-[22px]">
                    {t(
                      'Ya seas un futuro Birdybuyer buscando productos internacionales o un Birdybacker que quiere aprovechar sus maletas, tenemos todos los detalles para que emprendas el vuelo sin contratiempos.',
                    )}
                  </h5>
                </div>
              </div>

              <div className="h-[20px]" />

              <div className="flex flex-col gap-[0.5rem] items-center">
                <input
                  className="border-b-[0.2rem] border-primary p-[0.8rem] placeholder-primary text-primary bg-[#FFBA6E]/[.15] rounded focus:outline-none text-sm w-full"
                  placeholder="Buscar alguna pregunta?"
                  style={{ transition: 'all .15s ease' }}
                  onChange={handleChange}
                />

                <P>filtro</P>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-[4px]">
                  <Radio
                    onClick={() => getQA('Todo')}
                    id="Todo"
                    name="QA"
                    label={<p>Todo</p>}
                    defaultChecked
                  />
                  <Radio
                    onClick={() => getQA('Birdycommunity')}
                    id="Birdycommunity"
                    name="QA"
                    label={<p>Birdycommunity</p>}
                  />
                  <Radio
                    onClick={() => getQA('Birdybacker')}
                    id="Birdybacker"
                    name="QA"
                    label={<p>Birdybacker</p>}
                  />
                  <Radio
                    onClick={() => getQA('Birdybuyer')}
                    id="Birdybuyer"
                    name="QA"
                    label={<p>Birdybuyer</p>}
                  />
                </div>
              </div>

              <div className="h-[80px]" />

              {questions && questions[0] !== undefined ? (
                questions.map((question) => (
                  <Accordion
                    key={question.id}
                    open={open === question.id}
                    icon={<Icon id={question.id} open={open} />}
                  >
                    <AccordionHeader
                      className="bg-[#FFD2B0]/[.45] px-[1.5rem] md:px-[4rem]"
                      onClick={(e) => handleOpen(question.id, e)}
                    >
                      <div className="flex gap-[1rem] text-[#3FC2A0]">
                        <Highlighter
                          className="font-Roboto text-[18px] md:text-[23px] text-[#3FC2A0] text-start"
                          searchWords={[search]}
                          autoEscape={true}
                          textToHighlight={question.title}
                        />
                      </div>
                    </AccordionHeader>
                    <AccordionBody className="bg-[#FFD2B0]/[.15] px-[1rem] md:px-[4rem] py-[2rem]">
                      <Highlighter
                        className="font-Montserrat text-[16px] md:text-[18px]"
                        searchWords={[search]}
                        autoEscape={true}
                        textToHighlight={question.body}
                      />
                    </AccordionBody>
                  </Accordion>
                ))
              ) : (
                <div className="flex flex-col items-center">
                  <p className="text-primary">
                    {t('Ups! No podemos encontrar una palabra similar.')}
                  </p>
                  <div className="flex items-center">
                    <img className="w-[10rem]" src={logo} alt="Logo de Birdyback sin fuentes" />
                    <h1
                      className="font-[500] text-primary"
                      style={{ fontFamily: 'Arciform', userSelect: 'none' }}
                    >
                      birdyback
                    </h1>
                  </div>
                </div>
              )}

              {/* {questions && questions.length > 10 && (
                <>
                  <div className="h-[80px]" />
                  <div className="flex justify-center items-center gap-8">
                    <IconButton
                      size="sm"
                      variant="outlined"
                      color="blue-gray"
                      onClick={prev}
                      disabled={active === 1}
                    >
                      <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
                    </IconButton>
                    <p>Página {active} de 3</p>
                    <IconButton
                      size="sm"
                      variant="outlined"
                      color="blue-gray"
                      onClick={next}
                      disabled={active === 10}
                    >
                      <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
                    </IconButton>
                  </div>
                </>
              )} */}
            </div>
          </div>
        </MediumPadding>

        <Footer text="¡Queremos conectar contigo!" />
      </QABackground>
    </>
  );
};

export default QA;
