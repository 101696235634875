import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { productsApi } from '../../api/products';

let initialState = [
  {
    id: 1,
    category: 'technology',
    name: 'iphone',
    weight: 1,
    weight_metric_unit: 'kg',
    volume: 1,
    volume_metric_unit: 'cm',
    space: 1,
    price: 1,
    price_unity: 'us',
    product_link:
      'https://leronza.com/wp-content/uploads/2022/07/leronza-24k-gold-iphone-14-pro-with-crystal-logo.jpg',
    photos: [
      {
        name: 'Screenshot from 2023-05-12 01-37-46.png',
        type: 'photo',
        url: 'https://leronza.com/wp-content/uploads/2022/07/leronza-24k-gold-iphone-14-pro-with-crystal-logo.jpg',
        default: true,
      },
    ],
    amount: 1,
  },
  {
    id: 2,
    price_unity: 'us',
    category: 'technology',
    name: 'ABC',
    status: 'active',
    space: 'box',
    weight: '50',
    volume: '100',
    price: 500,
    product_link:
      'https://timaccesorios.com/wp-content/uploads/2023/01/Apple-iPhone-14-Pro-iPhone-14-Pro-Max-hero-220907.jpg.news_app_ed.jpg',
    weight_metric_unit: 'oz',
    volume_metric_unit: 'cm',
    alto: 1,
    ancho: 1,
    largo: 1,
    amount: 2,
  },
];

export const getProducts = createAsyncThunk(
  'product/products',
  async (Authorization, { rejectWithValue }) => {
    try {
      const response = await productsApi.list_products({ Authorization });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  },
);

export const productSlide = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      state.push(action.payload);
    },
    updateProduct: (state, action) => {
      const product = state.find((product) => product.id === action.payload.id);
      if (product) {
        product.category = action.payload.category;
        product.name = action.payload.name;
        product.price = action.payload.price;
        product.price_unity = action.payload.price_unity;
        product.volume_metric_unit = action.payload.volume_metric_unit;
        product.weight_metric_unit = action.payload.weight_metric_unit;
        product.product_link = action.payload.product_link;
        product.space = action.payload.space;
        product.alto = action.payload.alto;
        product.ancho = action.payload.ancho;
        product.largo = action.payload.largo;
        product.quantity = action.payload.quantity;
        product.amount = action.payload.amount;
      }
    },
    deleteProduct: (state, action) => {
      const product = state.find((product) => product.id === action.payload);
      if (product) {
        state.splice(state.indexOf(product), 1);
      }
    },

    reset: (state, action) => {
      return [action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state) => {
        return {
          ...state,
          loading: true,
          error: null,
        };
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        // console.log(action.payload);
        return {
          ...action.payload.result.items,
          loading: false,
          success: true,
        };
      })
      .addCase(getProducts.rejected, (state, action) => {
        return { ...state, loading: false, error: action.error.message };
      });
  },
});

export const { addProduct, deleteProduct, updateProduct, reset } =
  productSlide.actions;
export default productSlide.reducer;
