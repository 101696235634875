import React from 'react';

import Section from '../../ui/Section';

import SmallLeftCloud from '../../../assets/background/nube-pequeña-izquierda-azul-birdybacker.svg';
import smallCenteredCloud from '../../../assets/background/nube-pequeña-centrada-azul-birdybacker.svg';

const ContainerCardsBackground = ({ children }) => {
  return (
    <Section h="h-full">
      <img
        className="absolute w-[240px] md:w-auto top-[30vh] md:top-[15vh] lg:top-[30vh] left-0 z-[-1]"
        src={SmallLeftCloud}
        alt="Nube izquierda pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[240px] md:w-auto bottom-[40vh] md:bottom-[25vh] lg:bottom-[30vh] left-[25vw] md:left-[40vw] z-[-1]"
        src={smallCenteredCloud}
        alt="Nube centrada pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {children}
    </Section>
  );
};

export default ContainerCardsBackground;
