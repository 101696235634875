import { useTranslation } from 'react-i18next';
import { Tooltip } from 'flowbite-react';
import { BiHome, BiShoppingBag, BiCog, BiTrophy } from 'react-icons/bi';
import { logoutUser } from '../../../../features/auth/authSlide';
import { useDispatch } from 'react-redux';

const AsideMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const menuItems = [
    { label: t('Inicio'), link: '/', icon: <BiHome className='text-3xl' /> },
    { label: t('Guardadas'), link: '/saved', icon: <BiShoppingBag className='text-3xl' /> },
    { label: t('Ordenes'), link: '/orders', icon: <BiShoppingBag className='text-3xl' /> },
    { label: t('Configuración'), link: '/settings', icon: <BiCog className='text-3xl' /> },
  ];

  const logout = () => {
    localStorage.removeItem('auth');
    dispatch(logoutUser());
    navigate('/');
  };

  return (
    <div className="hidden ml-5 lg:flex lg:gap-x-12 items-center flex-col">
      <div className="flex items-center mb-6">
        <Tooltip content="Cambiar foto" placement="bottom">
          <img className="w-[80px] h-[80px] rounded-md" src="https://i.pravatar.cc/100"
            alt="profile" />
        </Tooltip>
        <div className="ml-4">
          <h3 className="text-[#0A7379] font-bold text-[25px]">Juan Orozco</h3>
          <StatusPill value="active" />
        </div>
      </div>
      <div className="w-full lg:pl-7">
        <ul className='mt-6'>
          {menuItems.map((item, index) => (
            <li key={index} className="mb-4 flex items-center hover:text-primary">
              {item.icon &&
                <span className="mr-2">{item.icon}</span>}
              <a
                className=" inline-flex w-[362px] font-Montserrat font-bold text-[#A1A1A1] text-center items-center hover:text-primary"
                href={item.link}
              >
                {item.label}
              </a>
            </li>
          ))}
          <li>
            <button
              className="lg:ml-10 text-[16px] leading-6 text-primary font-bold font-Montserrat hover:text-primary"
              onClick={logout}
            >
              {t('Cerrar sesión')}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export function StatusPill ({ value }) {
  const status = value ? value.toLowerCase() : "unknown";

  return (
    <span
      className={
        classNames(
          "px-3 py-1 leading-wide font-medium text-md rounded-full shadow-sm",
          status.startsWith("active") ? "bg-[#FFD27A] text-[#D88E00]" : null,
          status.startsWith("inactive") ? "bg-yellow-100 text-yellow-800" : null,
          status.startsWith("offline") ? "bg-red-100 text-red-800" : null,
        )
      }
    >
      <BiTrophy className='inline-flex mr-1' size={20} color="#D88E00" />
      870 millas
    </span>
  );
};

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
}

export default AsideMenu;
