import { useState } from 'react';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

const InputField = ({ type = 'text', label = '', value, onChange }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div className="relative mx-auto max-w-md">
      {type === 'password' ? (
        <>
          <input
            type={showPassword ? 'text' : 'password'}
            value={value}
            onChange={handleChange}
            className="block w-full h-10 rounded-full border-[2px] border-[#A1A1A1] pl-4"
          />
          <label
            className="absolute top-[-18px] left-[38px] mt-2 bg-white px-2 text-gray-600 text-[16px]"
          >
            {label}
          </label>
        </>
      ) : (
        <>
          <input
            type={type}
            value={value}
            onChange={handleChange}
            className="w-[300px] lg:w-[400px] block h-[63px] rounded-full border-[2.5px] border-[#A1A1A1] pl-4"
          />
          <label
            className="absolute top-[-18px] left-[38px] mt-2 bg-white px-2 text-gray-600 text-[16px]"
          >
            {label}
          </label>

        </>
      )}
      {type === 'password' && (
        <span className="absolute top-[10.5px] right-3 cursor-pointer" onClick={togglePasswordVisibility}>
          {showPassword ? <AiOutlineEye size={20} /> : <AiOutlineEyeInvisible size={20} />}
        </span>
      )}
    </div>
  );
};

export default InputField;
