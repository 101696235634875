import React from 'react';
import RoundedButton from '../../ui/RoundedButton';
import HandleScroll from '../../../util/functions/handleScroll';
import TravelerBackground from '../traveler/TravelerBackground';
import MediumPadding from '../../ui/MediumPadding';
import P from '../../typography/P';

const Container = ({ title, subtitle, text, text2, buttonText, image, to }) => {
  return (
    <TravelerBackground>
      <MediumPadding>
        <div className="flex flex-col md:flex-row justify-center items-center h-full px-[2rem] lg:px-[8rem] gap-[1rem] lg:gap-[2rem] 2xl:gap-[4rem] z-0">
          <div className="flex justify-center lg:justify-end w-[90vw] lg:w-1/2 wow fadeInUp">
            <img
              className="w-[300px] md:w-[550px] wow fadeInUp"
              src={image}
              alt="..."
            />
          </div>

          <div className="flex flex-col justify-around text-center md:text-start gap-[1.5rem] w-auto lg:w-1/2">
            <div>
              <h3 className="font-[600] text-primary lg:w-[26vw]">{title}</h3>

              {/* <h4 className="font-[400] text-black ">{subtitle}</h4> */}
            </div>

            <div className="pl-0 md:pl-[2rem] lg:pl-0 flex justify-center md:justify-start w-full">
              <div className="w-[80px] h-[3px] bg-[#C6D12D]" />
            </div>

            <P className="w-auto w-[300px] lg:w-[28rem]">{text}</P>
            <P className="w-auto w-[300px] lg:w-[28rem]">{text2}</P>
          </div>
        </div>
      </MediumPadding>
    </TravelerBackground>
  );
};

export default Container;
