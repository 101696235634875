const main = () => {
  let ubication = window.pageYOffset;
  window.onscroll = function () {
    const currentUbication = window.pageYOffset;
    const header = document.querySelector('.header');
    const navItems = document.querySelectorAll('.nav-item');
    const navShadow = document.querySelector('.nav-shadow');
    if (header) {
      if (ubication >= currentUbication) {
        header.classList.add('bg-white');
        header.classList.remove('bg-[#000]/[0.05]');
        navShadow.classList.add('shadow-lg');
        // header.classList.remove('top-[-80px]');

        // if (navItems) {
        //   navItems.forEach((item) => {
        //     item.classList.remove('opacity-[0.5]');
        //   });
        // }
      } else {
        header.classList.remove('bg-white');
        header.classList.add('bg-[#000]/[0.05]');
        navShadow.classList.remove('shadow-lg');
        // header.classList.add('top-[-80px]');

        // if (navItems) {
        //   navItems.forEach((item) => {
        //     item.classList.add('opacity-[0.5]');
        //   });
        // }
      }
      ubication = currentUbication;
      // if (window.pageYOffset > 0) {
      //   if (navItems) {
      //     navItems.forEach((item) => {
      //       if (item.classList.contains('text-white')) {
      //         item.classList.remove('text-white');
      //       }
      //       item.classList.add('text-primary');
      //     });
      //   }
      // } else {
      //   if (navItems) {
      //     navItems.forEach((item) => {
      //       if (item.classList.contains('text-primary')) {
      //         item.classList.remove('text-primary');
      //       }
      //       item.classList.add('text-white');
      //     });
      //   }
      // }
    }
  };
};

export default main;
