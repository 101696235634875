import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { Tooltip } from 'antd';

import RoundedButton from '../../ui/RoundedButton';
import RoundedCard from '../../../components/ui/RoundedCard';
import traveler from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdybacker-ganando-dinero-enviando-productos.svg';
import shopper from '../../../assets/BirdybackDesign/Artes finales Birdyback/Website-Illustrations/svg/birdybuyer-recibiendo-producto-enviado-usa.svg';
import HandleScroll from '../../../util/functions/handleScroll';
import { changeUserType } from '../../../features/auth/authSlide';
import SecondBackground from './SecondBackground';
import MediumPadding from '../../ui/MediumPadding';
import RegisterModal from '../../ui/RegisterModal';
import P from '../../typography/P';
import ModalSuscription from '../../../components/ui/ModalSuscription';

const SecondSection = () => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [user_type, setUser_Type] = useState('Birdybacker');

  const handleOnClick = (userType) => {
    // HandleScroll();
    dispatch(
      changeUserType({
        userType,
      }),
    );
    setUser_Type(userType);
    setModal(true);
  };

  return (
    <SecondBackground>
      <MediumPadding>
        {modal && (
          /*           <RegisterModal
                      modal={modal}
                      setModal={setModal}
                      user_type={user_type}
                    /> */
          <ModalSuscription
            modalOpen={modal}
            setModalOpen={setModal}
            typeUser={user_type}
          />
        )}

        <div className="flex flex-col lg:flex-row justify-center items-center gap-[6rem] md:gap-[4rem] lg:gap-[4rem] w-full h-full">
          <div className="flex flex-col text-center items-center w-auto wow fadeInLeft">
            <RoundedCard>
              <div className="flex justify-center w-full md:w-auto">
                <Link onClick={HandleScroll} to="/birdybuyer">
                  <img
                    src={traveler}
                    alt="Birdybacker (viajero) ganando dinero transportando y enviando un producto para un Birdybuyer (comprador) desde Estados Unidos a Colombia"
                    className="w-[300px] md:w-[350px] scale-1 hover:scale-[1.1] transition delay-[200ms] duration-[400ms]"
                  />
                </Link>
              </div>
              <div className="flex flex-col justify-center gap-[0.5rem] w-auto">
                <h3 className="block md:hidden font-[700] text-[#24BA93] ">
                  {t('Puedes ser Birdybacker')}
                </h3>

                <h5 className="hidden md:block font-[700] text-[#24BA93] ">
                  {t('Puedes ser Birdybacker')}
                </h5>

                <div className="flex flex-col">
                  <P className="w-[290px] md:w-[28rem]">
                    {t('Sabemos que')}{' '}
                    <Tooltip title="Hola Harri!!!!!!!!!!">
                      <span className="font-[600]">
                        {t('te gusta mucho viajar')}
                      </span>
                    </Tooltip>
                    {', '}
                    {t(
                      'y bueno, también el dinero extra. Acá puedes ser un',
                    )}{' '}
                    <span className="font-[600]">
                      {t('Birdybacker y ganar dinero mientras viajas')}
                    </span>{' '}
                    {t(
                      'de Estados Unidos a Colombia llevando en tu equipaje el producto de un Birdybuyer.',
                    )}
                  </P>
                </div>
              </div>
            </RoundedCard>

            <RoundedButton
              onClick={() => handleOnClick('Birdybacker')}
              // redirect="/signup"
              text={'¡VOY A VIAJAR!'}
            />
          </div>

          <div className="flex flex-col text-center items-center w-auto wow fadeInRight">
            <RoundedCard>
              <div className="flex justify-center w-full md:w-auto">
                <Link onClick={HandleScroll} to="/birdybacker">
                  <img
                    src={shopper}
                    alt="Birdybuyer (comprador) recibiendo gustosa y satisfactoriamente un producto transportado y enviado desde Estado Unidos hacia Colombia por un Birdybacker (viajero) utilizando Birdyback"
                    className="w-[300px] md:w-[350px] scale-1 hover:scale-[1.1] transition delay-[200ms] duration-[400ms]"
                  />
                </Link>
              </div>
              <div className="flex flex-col justify-center gap-[0.5rem] w-auto">
                <h3 className="block md:hidden font-[700] text-[#24BA93] ">
                  {t('Puedes ser Birdybuyer')}
                </h3>

                <h5 className="hidden md:block font-[700] text-[#24BA93] ">
                  {t('Puedes ser Birdybuyer')}
                </h5>
                <div className="flex flex-col">
                  <P className="w-[290px] md:w-[28rem]">
                    {t(
                      'Hacer una compra es fácil, lo difícil es encontrar quien la traiga desde otro país... Por eso,',
                    )}{' '}
                    {t('nosotros te ayudamos;')}{' '}
                    <span className="font-[600]">
                      {t(
                        'pide desde nuestra app lo que quieras de tu tienda favorita',
                      )}
                    </span>{' '}
                    {t(
                      'en Estados Unidos para que un Birdybacker te lo traiga a Colombia.',
                    )}
                  </P>
                </div>
              </div>
            </RoundedCard>

            <RoundedButton
              onClick={() => handleOnClick('Birdybuyer')}
              // redirect="/signup"
              text={'¡QUIERO COMPRAR!'}
            />
          </div>
        </div>
      </MediumPadding>
    </SecondBackground>
  );
};

export default SecondSection;
