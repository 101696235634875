import { t } from 'i18next';
import React from 'react';
import { NavLink } from 'react-router-dom';
import HandleScroll from '../../util/functions/handleScroll';

const NavItemResponsive = ({ redirect, text, setMobileMenuOpen }) => {
  return (
    <NavLink
      onClick={() => {
        HandleScroll();
        setMobileMenuOpen(false);
      }}
      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50"
      to={t(redirect)}
    >
      {t(text)}
    </NavLink>
  );
};

export default NavItemResponsive;
