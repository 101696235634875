import React from 'react';

import P from '../../typography/P';

const ValuesCard = ({ imagen, text }) => {
  return (
    // <div className="shadow-lg p-[2rem] m-[1rem] rounded-[12px]">
    <div className="flex flex-col justify-center items-center text-center w-full">
      <div className="flex justify-center">
        <img className="h-[250px] w-[250px]" src={imagen} alt="..." />
      </div>
      <P className="mt-[1.5rem] w-[18rem]">{text}</P>
    </div>
    // </div>
  );
};

export default ValuesCard;
