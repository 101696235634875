import React from 'react';

import Section from '../../ui/Section';

import largeCenteredCloud from '../../../assets/background/nube-grande-centrada-azul-birdybacker.svg';

const CommitmentsBackground = ({ children }) => {
  return (
    <Section h="h-full">
      <img
        className="absolute w-[260px] md:w-auto top-[5vh] right-[20vw] z-[-1]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[260px] md:w-auto top-[5vh] right-[20vw] z-[-1]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[260px] md:w-auto top-[5vh] right-[20vw] z-[-1]"
        src={largeCenteredCloud}
        alt="Nube centrada grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {children}
    </Section>
  );
};

export default CommitmentsBackground;
