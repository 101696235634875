import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { t } from 'i18next';

import H1 from '../components/typography/H1';
import Footer from '../layouts/Footer';
import ValuesContainer from '../components/pages/aboutUs/ValuesContainer';
import CommitmentsContainer from '../components/pages/common/CommitmentsContainer';
import FirstBanner from '../components/pages/aboutUs/FirstBanner';
import Parallax from '../components/pages/aboutUs/Parallax';
import HandleScroll from '../util/functions/handleScroll';
import ContactUsFAB from '../components/ui/ContactUsFAB';

const AboutPage = () => {
  useEffect(() => {
    return () => {
      HandleScroll();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Nosotros</title>
        <meta name='description' content='Acerca de Birdyback y nuestra Birdycommunity' />
      </Helmet>
      <ContactUsFAB />

      <div className="bg-KnowUsPhone md:bg-KnowUsTablet lg:bg-KnowUsComputer bg-center bg-cover h-[90vh] flex">
        <div className="w-1/2 flex flex-col justify-end pb-[4rem] md:pb-[3rem] lg:pb-[7rem] pl-[3rem] lg:pl-[9rem] h-full gap-[2rem]">
          <H1 className="font-[700] leading-[40px] lg:leading-[70px] w-[70vw] max-w-[25rem] text-white">
            {t('¡Estamos juntos en esto!')}
          </H1>

          <p className="hidden md:block text-white text-[20px] leading-[30px] w-[70vw] max-w-[25rem]">
            {t('Podemos traer')}{' '}
            <span className="font-[700]">
              {t('lo que necesitas hasta la puerta de tu casa')}
            </span>
            {'; '}
            {t('o si lo que quieres es')}{' '}
            <span className="font-[700]">
              {t('ganar dinerito extra mientras viajas')}
            </span>
            {', '}
            {t('también te ayudamos con eso.')}
          </p>

          <p className="block md:hidden text-white text-[20px] leading-[30px] w-[70vw] max-w-[25rem]">
            {t('Podemos traer')}{' '}
            <span className="font-[700]">
              {t('lo que necesitas hasta la puerta de tu casa')}
            </span>
            {'; '}
            {t('o si lo que quieres es')}{' '}
            <span className="font-[700]">
              {t('ganar dinerito extra mientras viajas')}
            </span>
            {'.'}
          </p>
        </div>
      </div>

      <ValuesContainer />

      <div className="flex flex-col text-center w-full h-full gap-[1rem] px-[1rem] md:px-[8rem] pt-[2rem]">
        <h2 className="font-[700] text-primary">
          {t('Así empezamos con Birdyback...')}
        </h2>

        <h5 className="text-primary">
          {t(
            'Tuvimos un problema, que otros también tenían, y no nos quedamos de manos cruzadas.',
          )}
        </h5>
      </div>

      <Parallax />

      <FirstBanner />

      <CommitmentsContainer />

      <Footer text="¡Queremos conectar contigo!" />
    </>
  );
};

export default AboutPage;
