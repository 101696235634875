import React from 'react';

import Section from '../../ui/Section';

import largeRightOrangeCloud from '../../../assets/background/nube-grande-derecha-naranja-birdybuyer.svg';
import leftMountain from '../../../assets/background/montaña-izquierda-azul-birdybacker.svg';

const TravelerMountainBackground = ({ children }) => {
  return (
    <Section h="h-full">
      <img
        className="absolute w-[240px] md:w-auto top-[-4vh] right-0 z-[-1]"
        src={largeRightOrangeCloud}
        alt="Nube derecha grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute h-[280px] md:h-auto w-full bottom-0 left-0 z-[-1]"
        src={leftMountain}
        alt="Montaña izquierda azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <div className="absolute bg-[#CFF6EB] h-[80px] md:h-[2vh] lg:h-[10vh] w-full bottom-[-2px] left-0 z-[-1]" />

      {children}
    </Section>
  );
};

export default TravelerMountainBackground;
