import React, { useEffect } from 'react';

import { Button, Form, Input, InputNumber, Select, Slider, Upload } from 'antd';
const { Option } = Select;
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import { addProduct, updateProduct } from '../../features/user/productsSlide';
import {
  addProductOfOrder,
  updateProductOfOrder,
} from '../../features/user/ordersSlide';
import { productsApi } from '../../api/products';

const FormGo = ({ setPage, product, Order }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    document.querySelector('.header').classList.add('top-[-80px]');

    if (product) {
      form.setFieldsValue({ ...product });
    }

    return () => {
      if (document.querySelector('.header')) {
        document.querySelector('.header').classList.remove('top-[-80px]');
      }

      if (product) {
        form.resetFields();
      }
    };
  }, []);

  const dispatch = useDispatch();

  const onFinish = async (values) => {
    productsApi
      .create_product(values)
      .then((response) => {
        console.log(response);
        Swal.fire(
          'Success',
          'Product has been saved correctly',
          'success',
        ).then(() => setPage('ShoppingCart'));
      })
      .catch((error) => {
        console.log('ERROR');
        console.log(error);
        Swal.fire('Error', 'something went wrong', 'error');
      });

    // if (Order) {
    //   if (product) {
    //     dispatch(
    //       updateProductOfOrder({ id_order: Order.id, ...product, ...values }),
    //     );
    //   } else {
    //     dispatch(addProductOfOrder({ id: Order.id, product: values }));
    //   }
    // } else {
    //   if (product) {
    //     dispatch(updateProduct({ ...product, ...values }));
    //   } else if (values) {
    //     dispatch(addProduct(values));
    //   }
    // }
    // // console.log(values);
  };

  return (
    <Form
      // labelCol={{ span: 4 }}
      // wrapperCol={{ span: 14 }}
      layout="vertical"
      style={{
        // display: 'flex', flexDirection: "column", justifyContent: 'center',
        margin: 10,
        // top: 100,
      }}
      onFinish={onFinish}
      requiredMark={false}
      form={form}
    >
      <Form.Item
        name="category"
        label="category"
        rules={[
          {
            // type: 'object',
            required: true,
            message: 'Es requerido!',
          },
        ]}
      >
        <Select>
          <Option value="technology">technology</Option>
        </Select>
      </Form.Item>

      <Form.Item name="name" label="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>

      <Form.Item
        name="product_link"
        label="product_link"
        rules={[
          { required: true },
          { type: 'url', warningOnly: true },
          { type: 'string', min: 6 },
        ]}
      >
        <Input placeholder="https://www.amazon.com/" />
      </Form.Item>

      {/* <Form.Item name="space" label="space">
        <Slider value={1} min={1} max={12} />
      </Form.Item> */}

      <div className="flex justify-around">
        <Form.Item name="length" label="length" rules={[{ required: true }]}>
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item name="width" label="width" rules={[{ required: true }]}>
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item name="height" label="height" rules={[{ required: true }]}>
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item
          name="length_unit"
          label="length_unit"
          rules={[{ required: true }]}
        >
          <Select>
            <Option value="in">in</Option>
            {/* <Option value="km">km</Option> */}
          </Select>
        </Form.Item>
      </div>
      <div className="flex justify-around">
        <Form.Item name="weight" label="weight" rules={[{ required: true }]}>
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item
          name="weight_unit"
          label="weight_unit"
          rules={[{ required: true }]}
        >
          <Select>
            <Option value="kg">kg</Option>
            <Option value="lb">lb</Option>
          </Select>
        </Form.Item>
      </div>

      <div className="flex justify-around">
        <Form.Item name="price" label="price" rules={[{ required: true }]}>
          <InputNumber min={1} />
        </Form.Item>

        <Form.Item
          name="quantity"
          label="quantity"
          rules={[{ required: true }]}
        >
          <InputNumber min={1} />
        </Form.Item>
      </div>

      <div className="flex justify-around"></div>

      <div className="flex justify-between">
        <Button
          onClick={() => {
            form.resetFields();
            setPage('ShoppingCart');
          }}
        >
          Volver
        </Button>

        <Button htmlType="submit">{product ? 'Editar' : 'Agregar'}</Button>
      </div>
    </Form>
  );
};

export default FormGo;
