export const tableQuestions0 = [
  {
    id: 0,
    title: '¿Cómo los puedo contactar directamente?',
    body: 'Siempre es mejor hablar con personas que con bots, ¿cierto? Nos puedes escribir por nuestro chat, llamarnos a nuestro teléfono o nos puedes enviar un mensaje por correo electrónico. Prometemos responder lo más rápido posible, porque no nos gusta dejarte esperando.',
    category: 'Birdycommunity',
  },
  {
    id: 1,
    title: '¿Qué hago si olvidé mi contraseña?',
    body: 'Olvidar la contraseña... ¡a todos nos pasa! Lo bueno es que te ayudamos a recuperarla. En el inicio de sesión, puedes dar clic en el mensaje “olvidé mi contraseña” y seguir el paso a paso; te llegará un correo electrónico para que crees una nueva contraseña. Por tu seguridad, no podemos restablecer manualmente tu contraseña, pero si tienes problemas recibiendo el correo para crear una nueva, recuerda mirar en el buzón de “correos no deseados” o escríbenos a ver cómo hacemos.',
    category: 'Birdycommunity',
  },
  {
    id: 2,
    title: '¿También están fuera de Estados Unidos y Colombia?',
    body: 'Por ahora, solo podemos hacer entregas que viajen desde Estados Unidos hasta Colombia. Pero estamos haciendo todo para poder aumentar nuestra comunidad a otros lugares del mundo lo más pronto posible. No te rindas con nosotros, lo lograremos. Muchas gracias por entender.',
    category: 'Birdycommunity',
  },
  {
    id: 3,
    title: '¿Qué es el panel de Momentos del Trayecto?',
    body: 'El panel de Momentos del Trayecto es el paso a paso del viaje que hace un pedido desde su encargo hasta su entrega; funciona tanto para Birdybackers como para Birdybuyers. Como Birdybacker puedes hacer check en los momentos del trayecto que vayas completando. Incluso, cuando recibas el pedido, te recomendamos tomar fotos e informar sobre los detalles que sean relevantes del producto, para asegurar que sí es ese el que necesita tu Birdybuyer. Y como Birdybuyer, puedes estar pendiente de los estados de tu compra, y hacer preguntas o anotaciones a tu Birdybacker para que las tenga en cuenta, y para que el producto que recibas tenga las características que estabas esperando.',
    category: 'Birdycommunity',
  },
  {
    id: 4,
    title: '¿Qué son los ratings?',
    body: 'Los ratings son el espacio en el que puedes dar tu opinión acerca del Birdybacker/Birdybuyer con el que te conectamos, para saber si fue responsable, tuvo compromiso y se portó bien contigo. A los buenos ratings les damos recompensas por su esfuerzo; y los no tan buenos pueden tener correctivos, con el objetivo de ver en qué están fallando para seguir mejorando. Esto nos importa porque nos ayuda a saber qué tan bien lo estamos haciendo como comunidad.',
    category: 'Birdycommunity',
  },
  {
    id: 5,
    title: '¿Qué son las millas?',
    body: 'Las millas son nuestra manera de decir con acciones “¡amamos que hagas parte de esto!”. Son recompensas y beneficios que tenemos para nuestra Birdycommunity; las entregamos en forma de cupones de descuentos con Birdyback y marcas aliadas, para agradecerte por pertenecer acá.',
    category: 'Birdycommunity',
  },
  {
    id: 6,
    title: '¿Cuántos productos puedo llevar?',
    body: 'Si quieres, puedes llevar un solo producto. También, puedes escoger la maleta que quieres que nosotros llenemos por ti; tenemos varias opciones para que tú decidas: maleta de mano, maleta pequeña de cabina (carry-on), maleta mediana de bodega (medium checked bag), maleta grande de bodega (large checked bag). Por último, puedes ser flexible y autorizar que Birdyback te proponga llevar desde uno producto hasta llenar una o varias maletas. Y lo mejor es que, si hace falta algo para completar el espacio que tienes disponible, te ayudamos buscando más Birdybuyers que quieran comprar productos para que puedas llevar tu maleta completamente llena.',
    category: 'Birdybacker',
  },
  {
    id: 7,
    title: '¿Las ofertas de Birdybuyers me las muestran solo a mí?',
    body: 'Es muy probable que le mostremos una misma oferta a distintos Birdybackers, porque nos gusta el juego limpio. Por eso, te aconsejamos que estés muy pendiente de tus notificaciones de la app de Birdyback, para que aceptes tu oferta preferida más rápido que los demás.',
    category: 'Birdybacker',
  },
  {
    id: 8,
    title: '¿Qué pasa si no encuentro buenas ofertas de Birdybuyers?',
    body: 'Para nosotros es importante tu comodidad. Por eso, te damos la libertad de hacer una contraoferta a un Birdybuyer. Lo que queremos es que aceptes las ofertas que realmente se acomoden a tus necesidades.',
    category: 'Birdybuyer',
  },
  {
    id: 9,
    title: '¿Cuánto me pagan por llevar un pedido a un Birdybuyer?',
    body: 'Te pagamos la misma cantidad de dinero que invertiste al comprar el producto a la tasa de cambio oficial del Banco de la Republica de Colombia. Con esto te estaríamos dando entre un 5% al 25% adicional a lo que recibes en casas de cambio tradicional. Finalmente, te damos el monto de envío que te pagó tu Birdybuyer.',
    category: 'Birdybacker',
  },
  {
    id: 10,
    title: '¿Qué puedo hacer para cancelar una oferta después de haberla aceptado?',
    body: 'Nosotros cuidamos a nuestros Birdybackers y Birdybuyers de la misma manera, y entendemos que a veces las cosas no salen como uno las planea. Por eso, en caso de que no tengas más opción que cancelar la entrega, nos gustaría que nos contaras porqué, y si es necesario haremos ajustes en tu rating.',
    category: 'Birdybacker',
  },
  {
    id: 11,
    title: '¿Qué pasa si cancelo varias órdenes?',
    body: 'Estamos siempre pendientes de la actividad de nuestros Birdybuyers y Birdybackers porque buscamos constantemente soluciones para hacerles todo más fácil. Si nos damos cuenta de que están cancelando ordenes de manera repetitiva, no nos queda más opción que aplicar restricciones. Estamos en esto juntos, y al hacer cancelaciones seguidas podemos afectar nuestra Birdycommunity.',
    category: 'Birdybacker',
  },
  {
    id: 12,
    title: '¿Hay algún problema si no puedo entregar el pedido en la hora acordada?',
    body: 'Entendemos que puedan surgir motivos para no poder entregar el pedido de tu Birdybuyer en la hora prevista; puedes decirnos con confianza las razones que tienes para hacerlo, y lo reagendamos fácilmente.',
    category: 'Birdybacker',
  },
  {
    id: 13,
    title: '¿Cómo puedo hablar con mi Birdybuyer para que modifiquemos la hora de entrega?',
    body: 'Puedes comunicarte con Birdyback directamente por medio del panel de Momentos. De todas maneras, ten en cuenta que la hora de entrega tiene un tiempo de tolerancia establecido que tu como Birdybacker debes cumplir.',
    category: 'Birdybacker',
  },
  {
    id: 14,
    title: '¿Cómo le entrego el pedido a mi Birdybuyer en Colombia?',
    body: 'Cuando estés en Colombia, puedes llevar el pedido solamente a una oficina de Coordinadora o Inter Rapidísimo, o programar que lo recojan donde te hospedas. Después de esto, subes al panel de Momentos del Trayecto la guía de tracking que te den en la agencia, para que tu Birdybuyer sepa que ya lo enviaste. Ah, y recuerda que el pedido debes enviarlo asegurado (esto ya está incluido en el precio).',
    category: 'Birdybacker',
  },
  {
    id: 15,
    title: '¿Cómo sé que sí me van a pagar?',
    body: 'Tu dinero está seguro; nosotros guardamos el pago que haga tu Birdybuyer, y te lo damos cuando entregues su pedido. Así, podemos cuidarlos a ambos.',
    category: 'Birdybacker',
  },
  {
    id: 16,
    title: '¿Puedo abrir el pedido de mi Birdybuyer antes de empacarlo en mi maleta?',
    body: '¡Claro que sí! Mejor dicho, ese es tu compromiso como Birdybacker. Queremos que veas el pedido antes de llevarlo en tu maleta, y también que tu Birdybuyer pueda confirmar que sí tiene las características de lo que pagó. Por eso, te pedimos el favor de que compartas fotos y la información importante en el panel de Momentos del Trayecto. Para que tu Birdybuyer le de el visto bueno antes de recibirlo, y tu sientas seguridad de meter el producto en tu maleta.',
    category: 'Birdybacker',
  },
  {
    id: 17,
    title: '¿Cuántos productos puedo comprar?',
    body: 'Si quieres pedir varios productos, ¡puedes hacerlo! Siempre y cuando cumplan con nuestras dimensiones y categorías permitidas. Nosotros te damos una caja para que la llenes con todo lo que quieras. Luego, de ser necesario, la repartimos en cajitas más pequeñas, para conectarte con los Birdybackers que puedan llevar tus productos en sus maletas.',
    category: 'Birdybuyer',
  },
  {
    id: 18,
    title: '¿Puedo hacer un pedido de varios productos enviados a distintas direcciones?',
    body: 'Si quieres comprar varias cositas en un mismo pedido y enviarlas a distintas direcciones, lo mejor es que hagas varios pedidos. Así es más fácil para los Birdybackers entender, organizar y traer tus encargos sin confusiones.',
    category: 'Birdybuyer',
  },
  {
    id: 19,
    title: '¿Cómo le hago seguimiento a mi pedido?',
    body: 'En el panel de Momentos puedes estar pendiente de todos los pasos del trayecto que hace tu pedido desde que lo encargas hasta que lo entregamos en tus manos.¿Te podemos dar un consejo? Bueno, puedes activar las notificaciones de la app para que veas rapidito cualquier cambio en el panel de Momentos.',
    category: 'Birdybuyer',
  },
  {
    id: 20,
    title: '¿Qué pasa si ningún Birdybacker puede traer mi pedido para la fecha que lo necesito?',
    body: 'Entendemos que es posible que la fecha en que necesitas recibir tu pedido no concuerde con el tiempo disponible de ningún Birdybacker, y queremos ayudarte, por eso no paramos de buscar soluciones. Sin embargo, también hay algo que puedes intentar: aumentando el monto de tu oferta de envío de pronto puedes encontrar un Birdybacker que se esfuerce por hacer llegar tu compra a tiempo.',
    category: 'Birdybuyer',
  },
  {
    id: 21,
    title: '¿Puedo seguir haciendo match con el mismo Birdybacker para mis próximas compras?',
    body: 'Nuestra Birdycommunity está en constante crecimiento y queremos poder ayudar a que se hagan nuevas conexiones. Pero sabemos que el mundo es muy pequeño, entonces es posible que vuelvas a hacer match con tu Birdybacker anterior; siempre y cuando ambos sigan cumpliendo con nuestros términos y condiciones.',
    category: 'Birdybuyer',
  },
  // {
  //   id: 22,
  //   title: '¿Puedo cancelar mi orden?',
  //   body: 'Sí y no, te explicamos: Si te arrepientes de comprar un producto, puedes cancelar antes de hacer match con un Birdybacker; te devolvemos tu dinero y te cobramos una pequeña comisión para cubrir algunos gastos. Si te arrepientes cuando ya tienes tu Birdybacker, también te devolvemos el dinero, y te pedimos una comisión un poquito más grande. Pero si quieres cancelar cuando ya tu pedido está viajando, lamentablemente no podemos hacer la cancelación; te entregaremos el pedido tal y como prometimos.',
  //   category: 'Birdybuyer',
  // },
  {
    id: 23,
    title: '¿Hay algún problema si cancelo varias órdenes?',
    body: 'Nosotros estamos siempre pendientes de la actividad de nuestros Birdybuyers y Birdybackers, porque buscamos constantemente soluciones para hacerles todo un poco más fácil. Si nos damos cuenta de que están cancelando órdenes de manera repetitiva, no nos queda más opción que aplicar restricciones; no porque ignoremos las emergencias ocurren, sino porque estamos en esto juntos, y al hacer cancelaciones seguidas podemos afectar nuestra Birdycommunity.',
    category: 'Birdybuyer',
  },
  {
    id: 24,
    title: '¿Qué pasa si mi Birdybacker me entrega un producto que no es el que yo compré?',
    body: 'Tranqui, nos aseguramos de que puedas ver tu producto antes de recibirlo. Cuando tu Birdybacker recibe el pedido, te envía fotos y cualquier detalle importante que tú debas confirmar; y eso es lo que te llevamos a tu casa. Además, para reforzar nuestro compromiso contigo, guardamos las facturas y fotos del pedido, y lo que recibes debe coincidir con eso.',
    category: 'Birdybuyer',
  },
  {
    id: 25,
    title: '¿Cuáles son los tiempos de entrega de mi pedido?',
    body: 'Bueno, el tiempo de entrega depende de la ubicación de tu Birdybacker y de su fecha de viaje. Es que, luego de que tu pedido llegue a la dirección de tu Birdybacker en Estados Unidos, la entrega se demora de 4 a 30 días. Nosotros nos comprometemos a cumplir con ese tiempo de entrega que te demos, sea el que sea; y podrás hacerle seguimiento a tu pedido en el panel de Momentos. Además, te contamos que estamos buscando maneras de hacer que cada vez más, puedas recibir tu pedido en menos tiempo.',
    category: 'Birdybuyer',
  },
  {
    id: 26,
    title: '¿Puedo cambiar mi dirección de entrega después de haber pagado mi orden?',
    body: 'Entendemos que puedas tener algún problema con tu dirección de entrega. Sí, la puedes cambiar antes de que tu pedido esté viajando, porque ya después será difícil para tu Birdybacker localizarte en otra dirección y entonces haremos la entrega en el lugar que acordamos desde el principio.',
    category: 'Birdybuyer',
  },
  {
    id: 27,
    title: '¿Cómo sé que no perderé mi dinero si surge algún problema con la orden?',
    body: 'Tranqui, acá tu dinero está seguro; nosotros lo guardamos, y solo lo entregamos a tu Birdybacker cuando hayas recibido el pedido satisfactoriamente. Así, podemos cuidarlos a ambos.',
    category: 'Birdybuyer',
  },
  {
    id: 28,
    title: '¿Y si mi pedido nunca llega?',
    body: '"Nuestra prioridad es que recibas tu pedido; pero en caso de que suceda algo que se nos salga de las manos y no podamos hacer la entrega, nosotros te cubrimos. Si antes de hacer la compra, cumpliste con toda la información que te pedimos, entonces te reembolsamos tu dinero y te cobramos una pequeña comisión para cubrir algunos gastos; y confiamos en poder entregar tu siguiente compra.',
    category: 'Birdybuyer',
  },
];
