import React from 'react';

import CardSecurity from './CardSecurity';
import ContainerCardsBackground from './ContainerCardsBackground';

const FirstSection = () => {
  return (
    <ContainerCardsBackground>
      <section className="flex flex-col justify-center items-center h-full my-[2rem]">
        <div className="grid grid-cols-1 md:grid-cols-2 max-w-[1600px]">
          {/* <CardSecurity
            title="Proceso de selección de socios de la App"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis"
          /> */}
          {/*<CardSecurity
            right
            title="Verificación de viajero"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis"
          /> */}
          <CardSecurity
            title="Terminos y condiciones"
            description="Protegemos tus datos con el mismo cariño con que protegemos a nuestra comunidad. Ten la tranquilidad de que tu información está segura con nosotros."
          />
          <CardSecurity
            right
            title="Políticas de privacidad"
            description="Queremos que disfrutes de Birdyback y su comunidad. Te invitamos a leer las reglas de juego para que todos podamos elevar el vuelo."
          />
        </div>
      </section>
    </ContainerCardsBackground>
  );
};

export default FirstSection;
