import React, { useState } from 'react';

import { t } from 'i18next';
import { ConfigProvider, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import ResponsiveInputEmail from './ResponsiveInputEmail';

import smallCenteredOrangeCloud from '../../assets/background/nube-pequeña-centrada-naranja-birdybuyer.svg';
import largeLeftOrangeCloud from '../../assets/background/nube-grande-izquierda-naranja-birdybuyer.svg';
import largeRightCloud from '../../assets/background/nube-pequeña-derecha-azul-birdybacker.svg';
import HeaderBuyerOrTraveler from './HeaderBuyerOrTraveler';
import checkItem from '../../assets/svg/icono-check-revisado-birdyback.svg';

//Api
import { PreRegister } from '../../api/preRegister'

const ModalContactSucess = ({ modalOpen, setModalOpen }) => {

  const nameElementsHeader = {
    firstNameElement: 'Birdybacker',
    SecondNameElement: 'Birdybuyer',
  }
  const [selectedOption, setSelectedOption] = useState(nameElementsHeader.firstNameElement);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setError(''); // Reset error state when email changes
  };

  const sendEmailData = async () => {
    try {
      console.log("Email:", email);
      console.log("type:", selectedOption);
      const res = await PreRegister.create_pre_register({ email, user_type: selectedOption });
      console.log(res);
      // alertas que se envio correctamente
    } catch (error) {
      console.log(error);
      // Lógica de manejo de errores y alertas
    }
  };


  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            borderRadiusLG: 40,
            paddingContentHorizontal: 0,
            paddingContentVertical: 0,
            paddingLG: 0,
            marginLG: 0,
            paddingMD: 0,
            paddingXL: 0,
            paddingSM: 0,
            paddingContentHorizontalLG: 0,
          },
        },
      }}
    >
      <Modal
        centered
        closeIcon={false}
        open={modalOpen}
        onOk={null}
        onCancel={null}
        footer={null}
        width={800}
      >
        <BackgroundModal>
          <div className="relative flex flex-col items-center gap-[1rem] py-[1rem] overflow-hidden z-[1]">
            <FontAwesomeIcon
              className="absolute top-[12px] right-[18px] text-[24px] cursor-pointer"
              onClick={() => setModalOpen(false)}
              icon={faXmark}
            />

            <div className="text-center">
              <h4 className="font-Montserrat text-primary font-[700]">
                {t('Mensaje Enviado Exitosamente')}
              </h4>

              <div className='flex justify-center'>
                <h6 className="font-Montserrat text-primary w-2/3">
                  {t('Dentro de los próximos días Birdyback se pondrá en contacto contigo.')}
                </h6>
              </div>
            </div>
          </div>
        </BackgroundModal>
      </Modal>
    </ConfigProvider >
  );
};

const BackgroundModal = ({ children }) => {
  return (
    <section className="relative">
      <img
        className="absolute top-[5vh] left-[32px] z-[0]"
        src={smallCenteredOrangeCloud}
        alt="Nube centrada pequeña naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute bottom-[0] left-0 z-[0]"
        src={largeLeftOrangeCloud}
        alt="Nube izquierda grande naranja ilustrada representando al Birdybuyer (comprador) de Birdyback"
      />

      <img
        className="absolute bottom-[160px] right-0 z-[0]"
        src={largeRightCloud}
      />

      {children}
    </section>
  );
};

export default ModalContactSucess;
