import { createSlice, current } from '@reduxjs/toolkit';

let initialState = [];

export const ordersSlide = createSlice({
  name: 'order',
  initialState,
  reducers: {
    addOrder: (state, action) => {
      state.push(action.payload);
    },
    addProductOfOrder: (state, action) => {
      const order = state.find((order) => order.id === action.payload.id);
      if (order) {
        order.products.push(action.payload.product);
        // state.push(action.payload);
      }
    },
    addAddress: (state, action) => {
      const order = state.find((order) => order.id === action.payload.id);
      if (order) {
        order.address = action.payload.address;
      }
    },
    updateOrder: (state, action) => {
      const order = state.find((order) => order.id === action.payload.id);
      if (order) {
        if (action.payload.products) {
          order.products = action.payload.products;
        }
        if (action.payload.section) {
          order.section = action.payload.section;
        }
        if (action.payload.address) {
          order.address = action.payload.address;
        }
      }
    },
    updateProductOfOrder: (state, action) => {
      // console.log(current(action.payload));
      const order = state.find((order) => order.id === action.payload.id_order);
      if (order) {
        // let product;
        // console.log(current(order));
        // console.log(current(order.products));
        const product = order.products.find(
          (product) => product.id === action.payload.id,
        );
        // console.log(current(newProducts));
        // console.log(current(state));
        // console.log(current(state.products));\
        if (product) {
          product.category = action.payload.category;
          product.name = action.payload.name;
          product.price = action.payload.price;
          product.price_unity = action.payload.price_unity;
          product.volume_metric_unit = action.payload.volume_metric_unit;
          product.weight_metric_unit = action.payload.weight_metric_unit;
          product.product_link = action.payload.product_link;
          product.space = action.payload.space;
          product.alto = action.payload.alto;
          product.ancho = action.payload.ancho;
          product.largo = action.payload.largo;
          product.amount = action.payload.amount;
          product.quantity = action.payload.quantity;
        }
      }
    },
    deleteProductOfOrder: (state, action) => {
      // console.log(current(action.payload));
      const order = state.find((order) => order.id === action.payload.id);
      if (order) {
        // let product;
        console.log(current(order));
        console.log(current(order.products));
        const newProducts = order.products.filter(
          (product) => product.id !== action.payload.id_product,
        );
        // console.log(current(newProducts));
        console.log(current(state));
        // console.log(current(state.products));\
        if (newProducts) {
          order.products = newProducts;
        } else {
          order.products = [];
        }
      }
    },
    deleteOrder: (state, action) => {
      const order = state.find((order) => order.id === action.payload);
      if (order) {
        state.splice(state.indexOf(order), 1);
      }
    },
  },
});

export const {
  addAddress,
  addProductOfOrder,
  addOrder,
  deleteOrder,
  deleteProductOfOrder,
  updateOrder,
  updateProductOfOrder,
} = ordersSlide.actions;
export default ordersSlide.reducer;
