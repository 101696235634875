import React from 'react';

import Section from '../../ui/Section';

import largeRightCloud from '../../../assets/background/nube-grande-derecha-azul-birdybacker.svg';
import smallLeftCloud from '../../../assets/background/nube-pequeña-izquierda-azul-birdybacker.svg';
import smallCenteredCloud from '../../../assets/background/nube-pequeña-centrada-azul-birdybacker.svg';

const HomeBackground = ({ children }) => {
  return (
    <Section>
      <img
        className="absolute w-[240px] md:w-auto top-0 md:top-[10vh] right-0 z-[-2]"
        src={largeRightCloud}
        alt="Nube derecha grande azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[160px] md:w-auto top-[50vh] md:top-[30vh] lg:top-[25vh] left-0 z-[-1]"
        src={smallLeftCloud}
        alt="Nube izquierda pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      <img
        className="absolute w-[260px] md:w-auto bottom-[4vh] lg:bottom-[12vh] left-[18vw] md:left-[42vw] z-[-1]"
        src={smallCenteredCloud}
        alt="Nube centrada pequeña azul ilustrada representando al Birdybacker (viajero) de Birdyback"
      />

      {children}
    </Section>
  );
};

export default HomeBackground;
