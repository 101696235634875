import React from 'react';

const RoundedCard = (props) => {
  return (
    <div className="flex flex-col h-[560px] lg:h-[550px] gap-[0.5rem] px-[2rem] rounded-[15px] md:rounded-[57px]">
      {props.children}
    </div>
  );
};

export default RoundedCard;
