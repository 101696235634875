import React from 'react';

import { t } from 'i18next';

const ParallaxItem = ({ children, img, text, alt }) => {
  return (
    <>
      <div className="flex justify-center items-center">
        <div className="w-[90vw] md:w-[75vw] flex justify-center gap-0 lg:gap-[4rem]">
          <div>
            <div className="flex lg:hidden flex-col gap-0 md:gap-[2vh] py-[1rem] px-[2rem]">
              <div className="flex justify-center md:justify-start items-center gap-[1rem]">
                {children}
              </div>
            </div>

            <div className="flex justify-center lg:justify-start">
              <img
                alt={alt}
                className="h-auto md:h-[500px] lg:h-[600px] w-[95vw] md:w-auto"
                src={img}
              />
            </div>

            <div className="flex lg:hidden justify-end items-center text-end gap-[1rem] mr-[2rem] mt-[1rem]">
              <h4 className="text-primary font-[700] w-[90vw]">{t(text)}</h4>

              <div className="w-[4px] h-[26px] bg-[#F98E19]" />
            </div>
          </div>

          <div className="hidden lg:flex flex-col justify-center items-center gap-[2rem]">
            <div className="flex flex-row md:flex-col py-[1rem] px-[2rem]">
              <div className="flex justify-center items-center gap-[1rem]">
                {children}
              </div>
            </div>

            <div className="flex items-center text-end gap-[1rem]">
              <h4 className="text-primary font-[700] w-[32rem]">{t(text)}</h4>

              <div className="w-[4px] h-[40px] bg-[#F98E19]" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ParallaxItem;
