import { useState } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { Link, redirect } from 'react-router-dom';
import main from '../assets/js/main';
import logo from '../assets/logos/logo-birdyback-sin-fuente.png';
import logoName from '../assets/logos/logo-con-nombre.svg';
import { useTranslation } from 'react-i18next';

function classNames (...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavBuyer = () => {
  const { t, i18n } = useTranslation();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const logout = () => {
    localStorage.removeItem('auth');
    // dispatch(logoutUser());
    window.location.reload(true);
    redirect('/');
  };

  return (
    <div className="relative">
      <header
        className="header top-0 left-0 z-1 flex w-full items-center bg-[#CFF6EB]"
        style={
          mobileMenuOpen
            ? { transition: 'top 0.5s', display: 'none' }
            : { transition: 'top 0.5s' }
        }
      >
        <nav
          className="flex w-full items-center justify-between px-[1rem] lg:px-[8rem]"
          aria-label="Global"
        >
          <div className='py-4'>
            <Link to="/">
              <img src={logoName} alt="logo" className="w-[240px]" alt="Logo de Birdyback sin fuentes y con nombre" />
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-primary"
              onClick={() => setMobileMenuOpen(true)}
            >
              {mobileMenuOpen ? (
                <XMarkIcon className="w-[50px]" aria-hidden="true" />
              ) : (
                <Bars3Icon className="w-[50px]" aria-hidden="true" />
              )}
            </button>
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between px-[1rem]">
              <Link to="/">
                <img src={logo} alt="logo" className="w-[80px]" alt="Logo de Birdyback sin fuentes" />
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <XMarkIcon className="w-[50px]" aria-hidden="true" />
              </button>
            </div>
            <div className="flow-root px-6">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <Link
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50"
                    to="/"
                  >
                    {t('home')}
                  </Link>
                  <Link
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50"
                    to="/orders"
                  >
                    {t('orders')}
                  </Link>
                  <Link
                    onClick={() => setMobileMenuOpen(false)}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50"
                    to="/settings"
                  >
                    {t('settings')}
                  </Link>
                  <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50">
                          {t('lan')}
                          <ChevronDownIcon
                            className={classNames(
                              open ? 'rotate-180' : '',
                              'h-5 w-5 flex-none',
                            )}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          <Disclosure.Button
                            as="button"
                            onClick={() => i18n.changeLanguage('en')}
                            className="block rounded-lg py-2 text-sm font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50 w-full"
                          >
                            {t('Inglés')}
                          </Disclosure.Button>
                          <Disclosure.Button
                            as="button"
                            onClick={() => i18n.changeLanguage('es')}
                            className="block rounded-lg py-2 text-sm font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50 w-full"
                          >
                            {t('Español')}
                          </Disclosure.Button>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
                <div className="py-6">
                  <Link
                    onClick={() => {
                      setMobileMenuOpen(false);
                      logout();
                    }}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold font-Montserrat leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {t('Cerrar sesión')}
                  </Link>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
    </div>
  );
};

export default NavBuyer;
